import React from 'react'
import { useTranslation } from 'react-i18next';

import { CelebrationModal } from '../Modal/CelebrationModal';
import { HighLight } from '../Highlight/HighLight';
import images from '../../../assets/images/illustrations';

type OfferCodeSuccessModalProps = {
  onClose: () => void;
}

export const OfferCodeSuccessModal: React.FC<OfferCodeSuccessModalProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();

  return <CelebrationModal open onClose={onClose} buttonText={t("gotIt")}>
    <img src={images.trophy} alt="celebration" />
    <h1>{t("wayToGo")}</h1>
    <h4>
      {t("yourOfferCodeApplied")}{" "}
      <br />
      {t("enjoy")}{" "}
      <HighLight>{t("firstlyForFree")}</HighLight>!
    </h4>
  </CelebrationModal >
}