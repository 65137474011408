import React from "react";
import { IonCard, IonCardContent } from "@ionic/react";
import { useTranslation } from "react-i18next";
import images from "../../../assets/images/illustrations";
import { useViewport } from "../../../hooks/useViewport";

export interface TestimonialProps {
  selectedCard: number;
}

export const Testimonial: React.FC<TestimonialProps> = (props) => {
  const { t } = useTranslation();
  const { isDesktop } = useViewport();

  const { selectedCard } = props;

  if (isDesktop) {
    return (
      <IonCard id="testimonial">
        <div className="color-blue-secondary text-center fy-padding-vertical-sm font-lg">
          {t(`subTextHeader_${selectedCard.toString()}`)}
        </div>
        <div className="color-gray-light ">
          <IonCardContent className="ion-text-center ion-margin-horizontal ion-padding-top">
            <img
              src={images.subscriptionTestimonial_1}
              alt="welcome"
              className="ion-margin-bottom fy-margin-horizontal display-block"
            />
            <div className="font-xl text-center fy-padding-top-sm fy-padding-bottom-xs">
              {t(`subTextCouple_${selectedCard.toString()}`)}
            </div>
            <div className="font-sm text-center">{t("subTextTenure_1")}</div>
            <div className="font-sm ion text-center font-italic fy-padding-vertical-md">
              {t(`subTextQuote_${selectedCard.toString()}`)}
            </div>
          </IonCardContent>
        </div>
      </IonCard>
    );
  } else {
    return (
      <IonCard id="testimonial-mobile" className="color-white">
        <div className="color-blue-secondary text-center fy-padding-vertical-sm font-lg">
          {t(`subTextHeader_${selectedCard.toString()}`)}
        </div>
        <IonCardContent className="ion-text-center ion-margin-horizontal ion-margin-top">
          <img
            src={images.subscriptionTestimonial_1}
            alt="welcome"
            className="ion-margin-bottom fy-margin-horizontal display-block"
          />
          <div className="font-xl text-center fy-padding-top-sm fy-padding-bottom-xs">
            {t(`subTextCouple_${selectedCard.toString()}`)}
          </div>
          <div className="font-sm text-center">{t("subTextTenure_1")}</div>
          <div className="font-sm ion text-center font-italic fy-padding-vertical-md">
            {t(`subTextQuote_${selectedCard.toString()}`)}
          </div>
        </IonCardContent>
      </IonCard>
    );
  }
};

//      <IonCardContent className="ion-text-center ion-margin-horizontal ion-margin-top">
