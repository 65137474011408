import React, { useState } from "react";
import { IonText, IonList, IonModal, IonContent } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { PAGE_URLS } from "../../../constants";
import { useSelector } from "../../../redux/hooks";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { Field } from "../../shared/Field/Field";
import { chevronForward } from "ionicons/icons";
import {
  getVersionCheckState,
  getSubscriptionState,
  getUsersState,
} from "../../../redux/selectors";
import subscription from "../../../redux/reducers/subscription";
import "./ManageUserAccount.scss";
import { CloseAccountModal } from "./CloseAccount/CloseAccountModal";

export const ManageUserAccount: React.FC = () => {
  const { versionCheck } = useSelector(getVersionCheckState);
  const { userId } = useSelector(getUsersState);
  const { subscription } = useSelector(getSubscriptionState);
  const sub = subscription?.subDetails || subscription;
  const history = useHistory();
  const { t } = useTranslation();
  const [modalOn, setModalOn] = useState<boolean>(false);
  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS} title={t("accountDetails")} />}
    >
      <Section>
        <IonList lines="none">
          {/* TODO: remove this feature-flag conditional once subscriptions is launched and stable: */}
          {versionCheck?.subscriptionInfo?.active &&
            !subscription?.subExempt &&
            !!sub.frequency &&
            sub.subscriptionOwner === userId && (
              <Field
                label={t("subscription")}
                icon={chevronForward}
                routerLink={PAGE_URLS.MORE_SETTINGS_ACCOUNT_SUBSCRIPTION}
              >
                <IonText>
                  {t(sub?.isPromotional ? "offerCodeApplied0" : "subscriptionBilling", {
                    frequency: t(`subFrequency_${subscription?.subDetails?.frequency || "annual"}`),
                    amount: numeral(subscription?.subDetails?.amount).format("$0,0.00"),
                  })}
                </IonText>
              </Field>
            )}
          <Field
            label=""
            icon={chevronForward}
            onClick={() => {
              setModalOn(true);
            }}
            testId="more_settings_closeAccount"
          >
            <IonText className="close-account-label">{t("closeAccount")}</IonText>
          </Field>
        </IonList>
        <CloseAccountModal isOpen={modalOn} setModalOn={setModalOn} />
      </Section>
    </PageTemplate>
  );
};
