import { MODAL_TYPES } from "../../constants";
import * as types from "../actionTypes";

export const setLoading = (loading: boolean) => {
  return {
    type: types.SET_LOADING,
    payload: {
      loading,
    },
  };
};

export const setToastMessage = (toastMessage: string) => {
  return {
    type: types.SET_TOAST_MESSAGE,
    payload: {
      toastMessage,
    },
  };
};

export const openModal = (modalType: keyof typeof MODAL_TYPES = "", props = {}) => {
  return {
    type: types.OPEN_MODAL,
    payload: {
      modal: modalType,
      props,
    },
  };
};
