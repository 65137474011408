import React from "react";
import { IonText, IonButton, IonFooter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Section } from "../../../shared/PageTemplate";
import { useViewport } from "../../../../hooks/useViewport";
import { PAGE_URLS } from "../../../../constants";
import "./CloseAccount.scss";
import { closeStep } from "./CloseAccountModal";

export const FreeAccount = (props: any) => {
  const { setStep, setModalOn } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useViewport();

  return (
    <>
      <Section className="close-account" data-testid="closeAccount_explain">
        <IonText className="close-account-margin font-title-3 semibold">
          <h2 className="font-title-3 semibold">{t("closeAccount_freeAccount_header")}</h2>
        </IonText>
        <IonText className="close-account-margin font-md">
          <p className="font-md">{t("closeAccount_freeAccount_desc")}</p>
        </IonText>
      </Section>
      <IonFooter className="close-account-footer ion-text-center ion-padding fy-section-card ion-no-border">
        <IonButton
          type="submit"
          data-testid="closeAccount_keepBenefits_close"
          className="semibold"
          onClick={() => {
            setModalOn(false);
            history.push(PAGE_URLS.HOME);
          }}
        >
          {t("closeAccount_freeAccount_keep")}
        </IonButton>
        <IonButton
          type="submit"
          fill="clear"
          className={`semibold ${isMobile && "w-50"}`}
          onClick={() => {
            setStep(closeStep.STEP6);
          }}
          data-testid="closeAccount_keepBenefits_close"
        >
          {t("closeAccount")}
        </IonButton>
      </IonFooter>
    </>
  );
};
