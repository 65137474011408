import { useSelector } from "react-redux";
import { getVersionCheckState } from "../redux/selectors";

export const useFeatureFlags = () => {
  const { versionCheck } = useSelector(getVersionCheckState);
  const features = {
    subscription: versionCheck?.subscriptionInfo?.active,
  };

  return features;
};
