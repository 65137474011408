import React from "react";
import { IonText, IonButton, IonFooter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PageTemplate, Section, NavHeader } from "../../../shared/PageTemplate";
import { PAGE_URLS } from "../../../../constants";
import images from "../../../../assets/images/welcome_slides";
import "./CloseAccount.scss";
import { openFAQ } from "../../../../vendors/intercom";
import { trackEvent } from "../../../../vendors/monitoring";

export const AccountClosed = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PageTemplate
      header={<NavHeader title={t("firstly")} showBackButton={false} />}
      footer={
        <IonFooter className="ion-text-center ion-padding ion-no-border close-account-closed-footer">
          <IonButton
            type="submit"
            className="semibold"
            onClick={() => {
              history.replace(PAGE_URLS.LOGOUT);
            }}
            data-testid="closeAccount_closed_goToLogin"
          >
            {t("closeAccount_closed_return")}
          </IonButton>
        </IonFooter>
      }
    >
      <Section className="close-account" data-testid="closeAccount_explain">
        <IonText className="close-account-margin font-title-3 semibold">
          <h2 className="font-title-3 semibold">{t("closeAccount_closed_header")}</h2>
        </IonText>
        <IonText className="close-account-margin font-md">
          <p className="font-md">{t("closeAccount_closed_desc")}</p>
        </IonText>
      </Section>
      <div className="ion-text-center ion-margin-horizontal fy-padding-vertical-lg">
        <img
          src={images.closeAccountImg}
          alt="generic-boardgame"
          className="close-account-game-img ion-margin-bottom"
        />
      </div>
      <Section>
        <IonText className="close-account-margin semibold">
          <h2 className="font-title-3 semibold">{t("faq")}</h2>
        </IonText>
        <IonButton
          size="small"
          color="secondary"
          fill="clear"
          data-testid="forgot-pass"
          routerLink={PAGE_URLS.FORGOT_PASS}
          onClick={() => {
            openFAQ();
            trackEvent("Tapped FAQ button", {
              pageLocation: "settings",
            });
          }}
          className="close-account-faq-margin"
        >
          {t("closeAccount_closed_faq")}
        </IonButton>
      </Section>
    </PageTemplate>
  );
};
