import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsState, getCategoryState } from "../../../redux/selectors";
import { formatAccountNumber } from "../../../utils/formatUtils";
import { getAccountSettingType } from "../../../utils/financialAccounts";
import { Account, AccountCollectionItem } from "../../../redux/reducers/accounts";
import { put } from "../../../utils/apiOps";
import { SetSharedModal } from "../../shared/Link/SetSharedModal";
import { getMyAccountsData } from "../../../redux/actions";
import { AccountTypesMapItem, ManageAccountsUrlParams } from "../../../types";
import { ACCOUNT_OWNER_TYPE, PAGE_URLS } from "../../../constants";
import { useUserName } from "../../shared/User/useUserName";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { useResponsiveInterface } from "../../../hooks/useResponsiveInterface";

const formatSharing = (hhInfo: any) => {
  if (hhInfo !== undefined) {
    const showTrans = getAccountSettingType(hhInfo.privilegeType.value || hhInfo.privilegeType)
      .shareTrans;
    if (showTrans && hhInfo.balanceShared) return "transBal";
    if (showTrans) return "transactions";
    if (hhInfo.balanceShared) return "balances";
    return "none";
  }
};

export const ManageLinkedAccount: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const _interface = useResponsiveInterface("alert");
  const { sourceInstitutionId, accountId } = useParams<ManageAccountsUrlParams>();
  const { myAccounts, otherAccountCollection } = useSelector(getAccountsState);
  const { accountTypesMap } = useSelector(getCategoryState);
  const { singlePlayerMode } = useUserName();

  const [present] = useIonAlert();

  const [openSetSharedModal, setOpenSetSharedModal] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const [selectedAccountType, setSelectedAccountType] = useState<string>();
  const [selectedAccountTypesArr, setSelectedAccountTypesArr] = useState<Array<any>>([]);
  const [selectedAccountNickname, setSelectedAccountNickname] = useState<string>();
  const [openSetNicknameAlert, setOpenSetNicknameAlert] = useState<boolean>(false);

  const ownerAlertProps = {
    header: t("sorry"),
    message: t("onlyOwnerCanEdit_msg"),
    buttons: [
      {
        text: t("gotIt"),
      },
    ],
  };

  useEffect(() => {
    if (accountTypesMap.length === 0) {
      return;
    }
    let newTypesArr: Array<any> = [];
    accountTypesMap.map((accountType: AccountTypesMapItem) => {
      newTypesArr = newTypesArr.concat(
        accountType?.subtypes.map((subType: string) => {
          return {
            label: `${t(accountType.name)}: ${t(subType)}`,
            value: `${accountType.name}: ${subType}`,
          };
        })
      );
      return null;
    });
    setSelectedAccountTypesArr(newTypesArr);
  }, [accountTypesMap, t]);

  useEffect(() => {
    const allAccounts: AccountCollectionItem[] = myAccounts.concat(otherAccountCollection || []);
    if (allAccounts.length === 0) {
      return;
    }
    let acct: any = null;
    allAccounts?.map((institution: any) => {
      if (institution && institution.sourceInstitutionId === sourceInstitutionId) {
        acct = institution.subBanks.find((a: Account) => a._id === accountId);
      }
    });
    if (!acct) {
      return;
    }
    const cleanAcct: Account = { ...acct };
    setSelectedAccount(cleanAcct);
    setSelectedAccountType(`${cleanAcct?.accountType}: ${cleanAcct?.accountSubType}`);
    setSelectedAccountNickname(cleanAcct?.accountNickname);
  }, [myAccounts, otherAccountCollection, sourceInstitutionId, accountId]);

  const handleType = (newType: string) => {
    const typeArray = newType.split(": ");
    put(true, {
      endpoint: "/accounts/editType",
      bodyData: {
        accountId: selectedAccount?._id,
        accountType: typeArray[0],
        accountSubType: typeArray[1],
      },
    }).then(() => {
      setSelectedAccountType(newType);
      dispatch(getMyAccountsData());
    });
  };

  const hanldeNickname = (newNickname: string) => {
    put(true, {
      endpoint: "/accounts/editNickname",
      bodyData: {
        accountId: selectedAccount?._id,
        accountNickname: newNickname,
      },
    }).then(() => {
      setSelectedAccountNickname(newNickname);
      dispatch(getMyAccountsData());
    });
  };

  const checkEditRights = (allowEditingOnJoint: boolean = false, showAlert = true) => {
    if (selectedAccount?.isOwner) {
      return true;
    }

    if (
      allowEditingOnJoint &&
      selectedAccount?.hhInfo[0].accountOwnershipType == ACCOUNT_OWNER_TYPE.JOINT
    ) {
      return true;
    }

    if (showAlert) {
      present(ownerAlertProps);
    }
  };

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={
        <NavHeader
          defaultHref={`${PAGE_URLS.MORE_MANAGE_ACCTS}/${sourceInstitutionId}`}
          title={selectedAccount?.institutionName || t("accounts")}
        />
      }
    >
      {selectedAccount && (
        <Section>
          <h1 className="ion-text-center font-title-3">
            {selectedAccount?.accountName || t("notSet")}{" "}
            {formatAccountNumber(selectedAccount?.accountNumber)}
          </h1>

          <IonList lines="none">
            <IonItem
              onClick={() => {
                if (checkEditRights(true)) {
                  setOpenSetNicknameAlert(true);
                }
              }}
              data-testid="accountNickname"
              detail={true}
              className="font-md"
            >
              <IonLabel>{t("nickname")}</IonLabel>
              <IonLabel className="ion-text-right" slot="end">
                {selectedAccountNickname || t("notSet")}
              </IonLabel>
            </IonItem>

            {singlePlayerMode ? null : (
              <>
                <IonItem
                  onClick={() => {
                    if (checkEditRights(false)) {
                      setOpenSetSharedModal(true);
                    }
                  }}
                  data-testid="accountOwnership"
                  detail={true}
                  className="font-md"
                >
                  <IonLabel>{t("ownership")}</IonLabel>
                  <IonLabel className="ion-text-right" slot="end">
                    {t(selectedAccount?.hhInfo[0].accountOwnershipType || "none")}
                  </IonLabel>
                </IonItem>

                <IonItem
                  onClick={() => {
                    if (checkEditRights(false)) {
                      setOpenSetSharedModal(true);
                    }
                  }}
                  data-testid="accountPrimary"
                  detail={true}
                  className="font-md"
                >
                  <IonLabel>{t("primaryUse")}</IonLabel>
                  <IonLabel className="ion-text-right" slot="end">
                    {t(getAccountSettingType(selectedAccount?.hhInfo[0].privilegeType).primary)}
                  </IonLabel>
                </IonItem>

                <IonItem
                  onClick={() => {
                    if (checkEditRights(false)) {
                      setOpenSetSharedModal(true);
                    }
                  }}
                  data-testid="accountSharing"
                  detail={true}
                  className="font-md"
                >
                  <IonLabel>{t("sharing")}</IonLabel>
                  <IonLabel className="ion-text-right" slot="end">
                    {t(formatSharing(selectedAccount?.hhInfo[0]) || "none")}
                  </IonLabel>
                </IonItem>
              </>
            )}

            <IonItem data-testid="accountType" className="font-md">
              <IonLabel>{t("type")}</IonLabel>
              {checkEditRights(true, false) ? (
                <IonSelect
                  value={selectedAccountType}
                  placeholder={t("selectOne")}
                  interface={_interface}
                  onIonChange={(e) => handleType(e.detail.value)}
                  data-testid="accountTypeSelect"
                >
                  {selectedAccountTypesArr.length > 0 &&
                    selectedAccountTypesArr.map((type, i) => {
                      return (
                        <IonSelectOption key={i} value={type.value}>
                          {type.label}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              ) : (
                <IonLabel onClick={() => checkEditRights(true)}>
                  {t(selectedAccount.accountType)}: {t(selectedAccount.accountSubType)}
                </IonLabel>
              )}
            </IonItem>
          </IonList>
          <SetSharedModal
            allowClose={true}
            account={selectedAccount}
            isOpen={openSetSharedModal}
            onClose={() => setOpenSetSharedModal(false)}
          />
        </Section>
      )}
      <IonAlert
        data-testid="edit-nickname-alert"
        isOpen={openSetNicknameAlert}
        onDidDismiss={() => setOpenSetNicknameAlert(false)}
        header={t("editAccountNickname")}
        inputs={[
          {
            name: "nickname",
            type: "text",
            placeholder: String(selectedAccountNickname || t("nickname")),
          },
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Update",
            cssClass: "confirmBtn",
            handler: (e) => hanldeNickname(e.nickname),
          },
        ]}
      />
    </PageTemplate>
  );
};
