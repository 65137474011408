import React from 'react'
import { useTranslation } from 'react-i18next';

import { CelebrationModal } from '../Modal/CelebrationModal';
import { HelpButton } from '../Support/HelpButton';
import { HighLight } from '../Highlight/HighLight';
import images from '../../../assets/images/illustrations';

type SubscriptionSuccessModalProps = {
  onClose: () => void;
}

export const SubscriptionSuccessModal: React.FC<SubscriptionSuccessModalProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();

  return <CelebrationModal open onClose={onClose} buttonText={t("takeMeToFirstly")}>
    <div className="font-xl ion-text-center ion-padding ion-margin-top">{t("yourSubscriptionHasStarted")}</div>
    <img src={images.trophy} alt="celebration" />
    <h1>{t("allSet")}</h1>
    <h4>
      {t("you")}{" "}
      <HighLight>{t("startedYourFirstly")}</HighLight>{" "}
      {t("subscriptionWayToGo")}
    </h4>
  </CelebrationModal >
}