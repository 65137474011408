import { useCallback, useState } from "react";
import { AppRate } from "@ionic-native/app-rate";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;
import dayjs from "dayjs";
import { trackEvent } from "../vendors/monitoring";

export const useAppRate = () => {
  /* AppRate config */
  const prefs = {
    usesUntilPrompt: 3,
    displayAppName: "Firstly",
    simpleMode: true,
    storeAppURL: {
      ios: process.env.REACT_APP_RATE_IOS_ID,
      android: process.env.REACT_APP_RATE_ANDROID_URL,
    },
    callbacks: {
      onRateDialogShow: (callback: any) => {
        console.debug("App Rate requested");
        trackEvent("app_rate_request", {
          category: window.location.pathname.replace(/\/+$/, ""),
        });
      },
      onButtonClicked: (buttonIdx: number, label: string) => {
        switch (label) {
          case "No, Thanks":
          case "Remind Me Later":
            Storage.set({ key: "ratedApp", value: "false" });
            Storage.set({ key: "lastPromptDate", value: dayjs().format() });
            return;
          case "Rate It Now":
            Storage.set({ key: "ratedApp", value: "true" });
            return;
          default:
            return;
        }
      },
    },
  };

  // set preferences in AppRate package
  AppRate.setPreferences(prefs);

  // check if app has been rated
  const checkRating = async (): Promise<any> => {
    const { value: ratedApp } = await Storage.get({ key: "ratedApp" });
    let hasRated = false;
    let neverAsked = false;

    if (ratedApp === "true") {
      console.debug("App rated already");
      trackEvent("app_already_rated", { category: window.location.pathname.replace(/\/+$/, "") });
      hasRated = true;
      neverAsked = false;
    }
    if (ratedApp === null) {
      neverAsked = true;
    }

    return {
      hasRated,
      neverAsked,
    };
  };

  // check last prompt date
  // returns true if user can be asked again
  const checkDate = async (): Promise<boolean> => {
    const today = dayjs();
    const lastPromptDate = await getlastPromptDate();
    const weeksSinceLastprompt = today.diff(lastPromptDate, "week", true);

    return weeksSinceLastprompt >= 1;
  };

  // Get string with last prompt date. Returns today if never asked before.
  const getlastPromptDate = async (): Promise<string> => {
    let { value: lastPromptDate } = await Storage.get({ key: "lastPromptDate" });

    let rateDate = "";
    if (lastPromptDate !== null && lastPromptDate !== undefined) {
      rateDate = lastPromptDate as string;
    } else {
      await Storage.set({ key: "lastPromptDate", value: dayjs().format() });
      rateDate = dayjs().format() as string;
    }
    return rateDate;
  };

  // request handler function
  const requestRatingHandler = async (platform: string): Promise<boolean | undefined> => {
    {
      if (platform !== "android" && platform !== "ios") {
        return false;
      }

      const { hasRated, neverAsked } = await checkRating();
      const canAskAgain = await checkDate();

      const showPrompt = neverAsked || (!hasRated && canAskAgain);

      setTimeout(() => {
        AppRate.promptForRating(showPrompt);
      }, 1500);
    }
  };

  const openRatingPrompt = useCallback(requestRatingHandler, []);
  return { getlastPromptDate, openRatingPrompt };
};
