import React from "react";
import {
  IonText,
  IonList,
  IonToggle,
  IonIcon,
  IonButton,
  IonAlert,
  IonListHeader,
} from "@ionic/react";
import { createOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import dayjs from "dayjs";

import "./Subscription.scss";

import { PAGE_URLS } from "../../../constants";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { Field } from "../../shared/Field/Field";
import { getSubscriptionState } from '../../../redux/selectors';
import { checkoutSubscription, editRenewalStatus, applyOfferCode } from "../../../redux/actions";
import cardLogos from "../../../assets/images/cards";
import { useSearchParam } from "../../../hooks/useSearchParam";
import { wineGlasses } from "../../../assets/icons/generic";

export const ManageSubscriptionPage: React.FC = () => {
  const dispatch = useDispatch();
  const { subscription, loading } = useSelector(getSubscriptionState)
  const [dialogOpen, setDialogOpen] = React.useState(false);
  useSearchParam('offer-dialog', () => setDialogOpen(true));

  const { subDetails } = subscription || {};
  const autoRenew = subDetails?.status === "active"
  const hasPaymentInfo = !!subscription?.paymentMethod?.card
  const expirationDate = subscription?.expirationDate || subDetails?.expirationDate
  const { t } = useTranslation();

  return (
    <PageTemplate
      pageProps={{ className: "manage-subscription-page" }}
      contentProps={{ fullscreen: true }}
      header={<NavHeader defaultHref={PAGE_URLS.MORE_SETTINGS_ACCOUNT} title={t("subscription")} />}
    >
      <Section>
        {loading ? null : subDetails?.isPromotional ? <>
          <IonIcon icon={wineGlasses} className="ion-margin" size="large" style={{ width: 80, height: 80 }} />
          <IonList lines="none">
            <IonListHeader className="font-md ion-margin-start">
              {t("firstlyOfferCodeAppliedHooray")}
            </IonListHeader>
            {!!subDetails?.promoDescription && <Field label={t("offerCodeDetails")}>
              <IonText>
                {subDetails?.promoDescription}
              </IonText>
            </Field>}
            <Field label={t("startDate")}>
              <IonText>
                {dayjs(subDetails?.createdOn).format("MM/DD/YYYY")}
              </IonText>
            </Field>
            <Field label={t("endDate")}>
              <IonText>
                {dayjs(subDetails?.expirationDate).format("MM/DD/YYYY")}
              </IonText>
            </Field>
          </IonList>
        </> : <IonList lines="none">
          <Field label={t("subscriptionStatus")}>
            <IonText className="d-flex ion-align-items-center">
              {t("autoRenew")}
              <IonToggle
                className="ml-auto"
                checked={autoRenew}
                disabled={loading}
                onIonChange={(e) => {
                  if (e.detail.checked !== autoRenew) {
                    dispatch(editRenewalStatus(!e.detail.checked))
                  }
                }}
              />
            </IonText>
          </Field>
          <Field label={t(autoRenew ? "renewalDate" : "expirationDate")}>
            <IonText>
              {dayjs(expirationDate).format("MM/DD/YYYY")}
            </IonText>
          </Field>
          <Field
            label={t("total")}
          >
            <IonText>{t("subscriptionBilling", {
              frequency: t(`subFrequency_${subDetails?.frequency || "annual"}`),
              amount: numeral(subDetails?.amount || 0).format("$0,0.00"),
            })}</IonText>
          </Field>
          <Field
            label={t("paymentMethod")}
            icon={createOutline}
            onClick={() => dispatch(checkoutSubscription())}
          >
            {hasPaymentInfo ? <IonText className="d-flex ion-align-items-center">
              <IonIcon
                className="credit-card-logo"
                icon={cardLogos[subscription?.paymentMethod?.card?.brand as keyof typeof cardLogos]}
              />
              ••{subscription?.paymentMethod.card.last4}
            </IonText> : <IonText className="text-muted">
              {t("notSelected")}
            </IonText>}
          </Field>
          <Field
            label=" "
            icon={createOutline}
            onClick={() => setDialogOpen(true)}
          >
            <IonText>
              {t("enterOfferCode")}
            </IonText>
          </Field>
          <IonButton
            fill="clear"
            onClick={() => dispatch(checkoutSubscription())}
            className={`d-flex font-lg ion-padding ion-margin-vertical ${hasPaymentInfo ? '' : 'weblink'}`}
          >
            {t(hasPaymentInfo ? "changePaymentMethod" : "addPaymentMethod")}
          </IonButton>
        </IonList>}
      </Section>
      <IonAlert
        isOpen={dialogOpen}
        onDidDismiss={() => setDialogOpen(false)}
        cssClass="ion-text-center"
        header={t("haveAnOfferCode")}
        inputs={[
          {
            name: "offerCode",
            type: "text",
            cssClass: "ion-text-center",
            placeholder: t("enterOfferCode")
          }
        ]}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
            cssClass: "secondary",
            handler: () => setDialogOpen(false)
          },
          {
            text: t("apply"),
            handler: ({ offerCode }) => {
              dispatch(applyOfferCode(offerCode))
              setDialogOpen(false);
            }
          }
        ]}
      />
    </PageTemplate>
  );
};
