import React, { useState } from "react";
import { IonModal, IonContent } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ModalHeader } from "../../../shared/Header/ModalHeader";
import { CloseReason } from "./CloseReason";
import { TellUsMore } from "./TellUsMore";
import { KeepBenefits } from "./KeepBenefits";
import { GoalBalances } from "./GoalBalances";
import { FreeAccount } from "./FreeAccount";
import { SubAccount } from "./SubAccount";
import { ConfirmClose } from "./ConfirmClose";
import { closeOutline } from "ionicons/icons";
import { useViewport } from "../../../../hooks/useViewport";
import "./CloseAccount.scss";
import { useSelector } from "../../../../redux/hooks";
import { getFundingAccount } from "../../../../redux/selectors";
import { getSubscriptionState } from "../../../../redux/selectors";
import { getAccountsState } from "../../../../redux/selectors";
import { getGoalsBalance } from "../../../../utils/goalUtils";

export enum closeStep {
  STEP1 = "reason",
  STEP2 = "detail",
  STEP3 = "benefits",
  STEP4 = "balances",
  STEP5_A = "type_free",
  STEP5_B = "type_sub",
  STEP6 = "confirm",
}

export const CloseAccountModal = (props: any) => {
  const { t } = useTranslation();
  const { isOpen, setModalOn } = props;
  const [reason, setReason] = useState("somethingElse");
  const [step, setStep] = useState<closeStep>(closeStep.STEP1);
  const { isMobile } = useViewport();
  const { allBalances } = useSelector(getAccountsState);
  const fundingAccount = useSelector(getFundingAccount);

  const modalHeightId = isMobile ? "close-account-modal-mobile" : "close-account-modal-desktop";

  const nextPage = (): string => {
    let next = closeStep.STEP1;
    const balance = getGoalsBalance(allBalances.hh);
    if (balance > 0) {
      // takes user to balance transfer page
      next = closeStep.STEP4;
    } else {
      next = closeStep.STEP6;
    }
    return next;
  };

  const modalScreens = {
    reason: <CloseReason setReason={setReason} setStep={setStep} nextPage={nextPage} />,
    detail: <TellUsMore reason={reason} setStep={setStep} nextPage={nextPage} />,
    benefits: <KeepBenefits setStep={setStep} setModalOn={setModalOn} nextPage={nextPage} />,
    balances: (
      <GoalBalances setStep={setStep} allBalances={allBalances} fundingAccount={fundingAccount} />
    ),
    type_free: <FreeAccount setStep={setStep} setModalOn={setModalOn} />,
    type_sub: <SubAccount setStep={setStep} setModalOn={setModalOn} />,
    confirm: <ConfirmClose setModalOn={setModalOn} />,
  };

  const currentStep = modalScreens[step];
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => setModalOn(false)}
      id={modalHeightId}
      onWillPresent={() => {
        setStep(closeStep.STEP1);
      }}
    >
      <IonContent fullscreen>
        <ModalHeader
          startBtnIcon={closeOutline}
          startBtnOnClick={() => {
            setModalOn(false);
          }}
          title={t("account")}
        />
        {currentStep}
      </IonContent>
    </IonModal>
  );
};
