import React, { useState } from "react";
import { IonModal, IonContent, IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ModalHeader } from "../../../shared/Header/ModalHeader";
import { Testimonial } from "../Testimonial";
import { useViewport } from "../../../../hooks/useViewport";
import { ActionSheet } from "../../../shared/PageTemplate";
import "./TrialExpirationModal.scss";
import { IonSlide, IonSlides, IonHeader, IonToolbar, IonFooter } from "@ionic/react";
import { PAGE_URLS, onboardingFlags, UNSUBBED_URLS } from "../../../../constants";
import { useHistory, useLocation } from "react-router";
import { getUsersState } from "../../../../redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { getSubscriptionState } from "../../../../redux/selectors";
import { checkoutSubscription, renewSubscription } from "../../../../redux/actions";
import dayjs from "dayjs";
import { openChatWithMessage } from "../../../../vendors/intercom";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import { trackEvent } from "../../../../vendors/monitoring";

const slideOpts = {
  initialSlide: 0,
  speed: 400,
};

export const TrialExpiration: React.FC = () => {
  const { isDesktop } = useViewport();
  const history = useHistory();
  const location = useLocation();
  const { subscription: featureActive } = useFeatureFlags()
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { userId, flag } = useSelector(getUsersState);

  const { subscription, loading } = useSelector(getSubscriptionState);
  const expirationDate = subscription?.expirationDate || subscription?.subDetails?.expirationDate;

  const open =
    featureActive &&
    subscription &&
    !loading &&
    !subscription?.activeSub &&
    !subscription?.subExempt &&
    (!expirationDate ||
      dayjs().isAfter(dayjs(expirationDate))) &&
    UNSUBBED_URLS.every((url: string) => !location.pathname.includes(url)) &&
    flag == onboardingFlags.FINISHED_ONBOARDING;
  const isSubOwner = subscription?.subDetails?.subscriptionOwner === userId;
  const hasPaymentMethod = subscription?.paymentMethod;
  const title = t(isSubOwner ? "startSubscriptionHeader" : "subscriptionExpired");
  const message = t(isSubOwner ? hasPaymentMethod ? "continueRenewSubscription" : "continuePaymentInfo" : "contactPartnerToContinuePayment")
  const help = () => {
    openChatWithMessage(t("pleaseHelpWithExpiredSubscription"));
    trackEvent("Tapped help button", { category: 'subscriptionExpired' });
  };

  if (isDesktop) {
    return (
      <IonModal id="expiration-modal-desktop" backdropDismiss={false} isOpen={open}>
        <IonHeader className="ion-padding-vertical ion-padding-horizontal" mode="ios">
          <IonToolbar className="ion-padding-vertical">
            <h2 className="font-title-2 ion-text-center ion-no-margin">
              {title}
            </h2>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="text-center fy-padding-top-sm">
            {isSubOwner && <div className="ion-text-center font-lg">{t("freeTrialEnded")}</div>}
            <div className="ion-text-center font-lg">{message}</div>
            <div className="fy-margin-horizontal w-card">
              <IonSlides id="slides-desktop" pager={true} options={slideOpts}>
                {[1, 2, 3, 4, 5].map((i) => {
                  return (
                    <IonSlide key={i}>
                      <Testimonial selectedCard={i} />
                    </IonSlide>
                  );
                })}
              </IonSlides>
            </div>
            {isSubOwner && <>
              <div className="fy-padding-vertical-md fy-margin-vertical-sm">
                <div className="ion-text-center font-md">{t("monthPrice")}</div>
                <div className="ion-text-center font-sm">{t("yearPrice")}</div>
              </div>
              <IonButton
                fill="solid"
                onClick={() => {
                  hasPaymentMethod ? dispatch(renewSubscription()) : dispatch(checkoutSubscription(true));
                }}
                className="ion-margin-end ion-margin-bottom"
              >
                {t(hasPaymentMethod ? "renewSubscription" : "addPaymentInfoButton")}
              </IonButton>
              <IonButton
                expand="block"
                fill="clear"
                size="large"
                routerLink={PAGE_URLS.MORE_SETTINGS_ACCOUNT_SUBSCRIPTION + "?offer-dialog=open"}
              >
                {t("enterOfferCode")}
              </IonButton>
            </>}
          </div>
        </IonContent>
        <IonFooter mode="ios" className="ion-padding-horizontal fy-feathered">
          <IonToolbar className="ion-justify-content-between">
            {" "}
            <IonButton
              routerLink={PAGE_URLS.MORE_SETTINGS_ACCOUNT}
              slot="start"
              size="small"
              fill="clear"
              color="secondary"
            >
              {t("closeAccount")}
            </IonButton>
            <IonButton onClick={help} slot="end" size="small" fill="clear" color="secondary">
              {t("help")}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    );
  } else {
    return (
      <IonModal id="expiration-modal-mobile" backdropDismiss={false} isOpen={open}>
        <ModalHeader endBtnText="Help" startBtnOnClick={() => "hi"}>
          <ActionSheet
            title="Help"
            buttonProps={{ size: "medium", "data-testid": "modalEndBtn" }}
            buttons={[
              {
                text: t("closeAccount"),
                handler: () => history.push(PAGE_URLS.MORE_SETTINGS_ACCOUNT),
              },
              {
                text: t("help"),
                handler: help,
              },
            ]}
          />
        </ModalHeader>
        <IonContent scrollY={false}>
          <div className="text-center fy-padding-top-sm">
            <div className="fy-margin-horizontal-sm">
              <div className="ion-padding-top">
                <div className="ion-text-center font-lg fy-padding-bottom-sm">
                  {title}
                </div>
                {isSubOwner && <div className="ion-text-center font-sm">{t("freeTrialEnded")}</div>}
                <div className="ion-text-center font-sm">{message}</div>
              </div>
              <IonSlides dir="portrait" id="slides-mobile" pager={true} options={slideOpts}>
                {[1, 2, 3, 4, 5].map((i) => {
                  return (
                    <IonSlide key={i}>
                      <Testimonial selectedCard={i} />
                    </IonSlide>
                  );
                })}
              </IonSlides>{" "}
              {isSubOwner && <>
                <div className="fy-padding-vertical-md fy-margin-vertical-sm">
                  <div className="ion-text-center font-md">{t("monthPrice")}</div>
                  <div className="ion-text-center font-sm">{t("yearPrice")}</div>
                </div>
                <IonButton
                  expand="block"
                  size="large"
                  fill="solid"
                  onClick={() => {
                    hasPaymentMethod ? dispatch(renewSubscription()) : dispatch(checkoutSubscription(true));
                  }}
                  className="ion-margin-horizontal"
                >
                  {t(hasPaymentMethod ? "renewSubscription" : "addPaymentInfoButton")}
                </IonButton>
              </>}
              <IonButton
                expand="block"
                size="large"
                fill="clear"
                routerLink={PAGE_URLS.MORE_SETTINGS_ACCOUNT_SUBSCRIPTION + "?offer-dialog=open"}
              >
                {t("enterOfferCode")}
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonModal>
    );
  }
};
