import { InAppBrowser, InAppBrowserEvent, InAppBrowserObject } from "@ionic-native/in-app-browser";
interface BrowserTabOptions {
  url: string;
  platform: string;
}

export class BrowserTab {
  public url: string;
  private resolve: any;
  private reject: any;
  private platform: string;
  private tabOptions = "hardwareback=no,location=no,toolbar=no,hidden=yes";

  constructor({ url, platform }: BrowserTabOptions) {
    this.url = url;
    this.platform = platform;
  }

  public open(): Promise<any> {
    setTimeout(() => {
      if (this.platform === "web") {
        this.openWebWindow();
      } else {
        this.openAppWindow();
      }
    }, 500);

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  private openWebWindow() {
    console.debug("using window");
    const browser = window.open(this.url, "_blank", this.tabOptions);
    if (browser) {
      window.addEventListener("message", (event) => {
        console.debug(event);
        this.onBrowserMessage(event, browser);
      });
      const timer = setInterval(() => {
        if (browser.closed) {
          clearInterval(timer);
          this.onBrowserClose(true);
        }
      }, 300);
    }
  }

  private openAppWindow() {
    console.debug("using plugin");
    const browser = InAppBrowser.create(this.url, "_blank", this.tabOptions);
    browser.on("exit").subscribe((event) => {
      this.onBrowserClose(true);
    });
    browser.on("message").subscribe((event) => {
      this.onBrowserMessage(event, browser);
    });

    setTimeout(() => browser.show(), 500);
  }

  private onBrowserClose(userClosed: boolean) {
    console.debug(`window closed by ${userClosed ? "user" : "system"}`);
    setTimeout(() => this.resolve(), 500);
  }

  private onBrowserMessage(
    message: MessageEvent | InAppBrowserEvent,
    browser: InAppBrowserObject | Window
  ) {
    try {
      let messageData = message.data;
      if (typeof messageData === "string") {
        messageData = JSON.parse(message.data);
      }
      if (messageData?.source === "firstly") {
        browser.close();
        this.resolve();
      }
    } catch (e) {
      console.error(e);
      this.reject();
    }
  }
}
