import React, { useState } from "react";
import {
  IonActionSheet,
  IonButton,
  IonIcon,
  IonPopover,
  IonList,
  IonItem,
  IonText,
} from "@ionic/react";
import { useViewport } from "../../../hooks/useViewport";
import { ellipsisHorizontalSharp } from "ionicons/icons";
import _ from "lodash";

type ActionSheetButtonProps = {
  text?: string;
  role?: "cancel" | string;
  icon?: string;
  cssClass?: string;
  handler?: () => void;
};

type ActionSheetProps = {
  title?: string;
  header?: string; // will only display on mobile
  subHeader?: string; // will only display on mobile
  cssClass?: string;
  buttons: (ActionSheetButtonProps | string)[];
  id?: string;

  buttonProps?: any;
  hideButton?: boolean;
  openEvent?: Event;
  isOpen?: boolean;
  onDidDismiss?: () => void;
};

export const ActionSheet: React.FC<ActionSheetProps> = (props) => {
  const {
    title,
    header,
    subHeader,
    cssClass,
    buttons = [],
    buttonProps = {},
    hideButton = false,
    id,
    openEvent: externalOpenEvent,
    isOpen = false,
    onDidDismiss = () => {},
  } = props;
  const { isDesktop } = useViewport();
  const [openEvent, setOpenEvent] = useState<Event | undefined>();
  const _openEvent = openEvent || externalOpenEvent;
  const _isOpen = isOpen || !!_openEvent;
  const close = () => {
    setOpenEvent(undefined);
    onDidDismiss();
  };
  return (
    <>
      {hideButton ? null : (
        <IonButton
          fill="clear"
          size="small"
          {...buttonProps}
          onClick={(e) => {
            e.persist();
            e.stopPropagation();
            setOpenEvent((e as any) as Event);
          }}
        >
          {title || <IonIcon icon={ellipsisHorizontalSharp} />}
        </IonButton>
      )}
      {isDesktop ? (
        <IonPopover
          isOpen={_isOpen}
          event={_openEvent}
          onDidDismiss={close}
          showBackdrop={false}
          keyboardClose
        >
          <IonList>
            {buttons.map((button, index) => {
              const text = typeof button === "string" ? button : button.text;
              const handler = () => {
                if (typeof button !== "string" && button.handler) button.handler();
                close();
              };
              return <ActionSheetButton {...button} key={text} text={text} handler={handler} />;
            })}
          </IonList>
        </IonPopover>
      ) : (
        <IonActionSheet
          header={header}
          subHeader={subHeader}
          isOpen={_isOpen}
          onDidDismiss={close}
          buttons={buttons}
          id={id}
          cssClass={cssClass}
        />
      )}
    </>
  );
};

const ActionSheetButton: React.FC<ActionSheetButtonProps> = (props) => {
  const { text, role, icon, cssClass, handler } = props;
  return (
    <IonItem
      data-testid={_.kebabCase(text)}
      className={cssClass}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handler && handler();
      }}
      lines="none"
      button
    >
      {icon || null}
      <IonText>{text}</IonText>
    </IonItem>
  );
};
