const images = {
  amex: require("./amex.svg"),
  diners: require("./diners.svg"),
  discover: require("./discover.svg"),
  jcb: require("./jcb.svg"),
  mastercard: require("./mastercard.svg"),
  visa: require("./visa.svg"),
  unknown: require("./unknown.svg"),
};
export default images;
