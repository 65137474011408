import React from "react";
import { IonText, IonButton, IonFooter, IonToggle } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Section } from "../../../shared/PageTemplate";
import { useSelector } from "../../../../redux/hooks";
import { useViewport } from "../../../../hooks/useViewport";
import { PAGE_URLS } from "../../../../constants";
import { getSubscriptionState } from "../../../../redux/selectors";
import { Field } from "../../../shared/Field/Field";
import dayjs from "dayjs";
import { closeStep } from "./CloseAccountModal";
import "./CloseAccount.scss";

export const SubAccount = (props: any) => {
  const { setStep, setModalOn } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useViewport();
  const { subscription } = useSelector(getSubscriptionState);
  const autoRenew = subscription?.subDetails?.status === "active";
  const expirationDate = subscription?.expirationDate || subscription?.subDetails?.expirationDate;

  return (
    <>
      <Section className="close-account" data-testid="closeAccount_explain">
        <IonText className="close-account-margin font-title-3 semibold">
          <h2 className="font-title-3 semibold">{t("closeAccount_subscription_header")}</h2>
        </IonText>
        <IonText className="close-account-margin font-md">
          <p className="font-md">{t("closeAccount_subscription_desc1")}</p>
          <p className="font-md">{t("closeAccount_subscription_desc2")}</p>
        </IonText>

        <Field label={t("subscriptionStatus")}>
          <IonText className="d-flex ion-align-items-center">
            <p className="text-faded close-account-faded-label">{t("autoRenew")}</p>
            <IonToggle
              className="ml-auto"
              checked={autoRenew}
              disabled={true}
              data-testid="closeAccount_subscription_status"
            />
          </IonText>
        </Field>
        <Field
          label={t(autoRenew ? "renewalDate" : "expirationDate") as string}
          className="text-faded close-account-faded-label"
        >
          <IonText
            className="text-faded d-flex ion-align-items-center"
            data-testid="closeAccount_subAccount_expiration"
          >
            {dayjs(expirationDate).format("MM/DD/YYYY")}
          </IonText>
        </Field>
      </Section>
      <IonFooter className="close-account-footer ion-text-center ion-padding fy-section-card ion-no-border">
        <IonButton
          type="submit"
          data-testid="closeAccount_subAccount_autoRenew"
          className="semibold"
          onClick={() => {
            setModalOn(false);
            history.push(PAGE_URLS.MORE_SETTINGS_ACCOUNT_SUBSCRIPTION);
          }}
        >
          {t("subRenewTurnOff")}
        </IonButton>
        <IonButton
          type="submit"
          fill="clear"
          className={`semibold ${isMobile && "w-50"}`}
          onClick={() => {
            setStep(closeStep.STEP6);
          }}
          data-testid="closeAccount_subAccount_close"
        >
          {t("closeAccount")}
        </IonButton>
      </IonFooter>
    </>
  );
};
