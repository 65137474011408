import React from "react";
import {
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonText,
  IonButton,
  IonFooter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../shared/PageTemplate";
import "./CloseAccount.scss";
import { closeStep } from "./CloseAccountModal";

export const CloseReason = (props: any) => {
  const { setReason, setStep, nextPage } = props;
  const { t } = useTranslation();

  const closingReason = [
    "technicalIssues",
    "otherSolutions",
    "notNeeded",
    "trust",
    "price",
    "somethingElse",
  ];

  return (
    <>
      <Section className="close-account">
        <IonText className="close-account-margin">
          <h2 className="font-title-3 semibold">{t("closeAccount")}</h2>
          <p className="medium">{t("closeAccount_reason_desc")}</p>
        </IonText>

        <IonList className="close-account-list">
          <IonItemGroup data-testid="closeAccount_reasons">
            {closingReason.map((reason) => (
              <IonItem
                detail
                key={reason}
                onClick={() => {
                  setReason(reason);
                  setStep(closeStep.STEP2);
                }}
                className="close-account-item"
                data-testid={`closeAccount_reason_${reason}`}
              >
                <IonLabel className="close-account-reason ion-text-wrap">
                  {t(`closeAccount_reason_${reason}`)}
                </IonLabel>
              </IonItem>
            ))}
          </IonItemGroup>
        </IonList>
      </Section>
      <IonFooter className="close-account-footer ion-text-center ion-padding ion-no-border">
        <IonButton
          data-testid="closeAccount_reasons_skip"
          type="submit"
          expand="block"
          fill="clear"
          onClick={() => {
            setStep(nextPage());
          }}
        >
          {t("closeAccount_reason_skip")}
        </IonButton>
      </IonFooter>
    </>
  );
};
