import React from "react";
import {
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonText,
  IonButton,
  IonFooter,
  IonIcon,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Section } from "../../../shared/PageTemplate";
import { useViewport } from "../../../../hooks/useViewport";
import { PAGE_URLS } from "../../../../constants";
import images from "../../../../assets/images/welcome_slides";
import { UserBenefits } from "../../../../types/accounts";
import "./CloseAccount.scss";

export const KeepBenefits = (props: any) => {
  const { setStep, setModalOn, nextPage } = props;
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const history = useHistory();

  const benefits: UserBenefits[] = [
    { img: images.benefits_0, text: "unlimitedAcct" },
    { img: images.benefits_1, text: "autoSave" },
    { img: images.benefits_2, text: "customBudgets" },
    { img: images.benefits_3, text: "spendNotifications" },
    { img: images.benefits_4, text: "sharing" },
  ];

  return (
    <>
      <Section className="close-account">
        <IonText className="close-account-margin">
          <h2 className="font-title-3 semibold">{t("closeAccount_anotherChance_header")}</h2>
          <p className="medium">{t("closeAccount_anotherChance_desc1")}</p>
          <p className="medium">{t("closeAccount_anotherChance_desc2")}</p>
        </IonText>

        <IonList lines="none" className="margin-top-24" data-testid="closeAccount_benefits">
          <IonItemGroup data-testid="closeAccount_allBenefits">
            {benefits.map((item) => (
              <IonItem key={item.text}>
                <IonIcon icon={item.img} className="close-account-benefit-icon" />
                <IonLabel className="close-account-reason ion-text-wrap">
                  {t(`closeAccount_anotherChance_${item.text}`)}
                </IonLabel>
              </IonItem>
            ))}
          </IonItemGroup>
        </IonList>
      </Section>
      <IonFooter className="close-account-footer ion-text-center ion-padding ion-no-border fy-margin-horizontal">
        <IonButton
          type="submit"
          className={`semibold ${isMobile && "w-50"}`}
          onClick={() => {
            setModalOn(false);
            history.replace(PAGE_URLS.HOME);
          }}
          data-testid="closeAccount_keepBenefits_keep"
        >
          {t("closeAccount_anotherChance_keepAccount")}
        </IonButton>
        <IonButton
          type="submit"
          fill="clear"
          className={`semibold ${isMobile && "w-50"}`}
          onClick={() => {
            setStep(nextPage());
          }}
          data-testid="closeAccount_keepBenefits_close"
        >
          {t("closeAccount")}
        </IonButton>
      </IonFooter>
    </>
  );
};
