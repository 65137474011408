import { useHistory } from "react-router";

export const useSearchParam = (paramKey: string, cb?: (paramValue: string) => void) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const paramValue = searchParams.get(paramKey);
  if (cb && paramValue) {
    cb(paramValue);
    history.replace(history.location.pathname);
  };
  return paramValue;
}