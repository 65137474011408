import {
  BillFrequency,
  BudgetSuperCategory,
  BudgetType,
  BillReminderDaysOptions,
  BillSharedStatus,
} from "./types";

interface API_BASE {
  baseURL: any;
  headers: any;
}

export const API_BASE: API_BASE = {
  baseURL: process.env.REACT_APP_API_URL + "api/",
  headers: {
    api_key: process.env.REACT_APP_API_KEY,
    "content-type": "application/json",
  },
};

export const API_STATUS_CODES: any = {
  OK: 200,
  UNAUTHORIZED: 401,
  NO_CONTENT: 204,
};

export const BALANCE_TYPES = {
  CASH: "Cash",
  CREDIT: "Credit",
  INVEST: "Investments",
  LOAN: "Loans",
  PROPERTY: "Property",
  OTHER: "Other",
};
export const TYPE_TRANSLATE = {
  CASH: "cash",
  CREDIT: "credit",
  INVEST: "investments",
  LOAN: "loans",
  OTHER: "other",
  PROPERTY: "property",
};
export const FINANCIAL_ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  CLOSED: "CLOSED",
};
export const ACCOUNT_OWNER_TYPE = {
  JOINT: "joint",
  INDIVIDUAL: "indiv",
};
export const ACCOUNT_OWNERSHIP = {
  JOINT: "JOINT",
  INDIVIDUAL: "INDIVIDUAL",
};
export const ACCOUNT_PRIMARY_TYPE = {
  HOUSEHOLD: "household",
  PERSONAL: "personal",
};
export const PRIVILEGE_TYPE_DATA_2 = {
  SHARED: "0",
  VISIBLE: "1",
  IGNORE: "3",
  JUSTME: "4",
};
export const PRIVILEGE_TYPE_DATA_3 = {
  SHARED: "SHARED",
  VISIBLE: "VISIBLE",
  IGNORE: "IGNORE",
  JUSTME: "JUSTME",
};
interface BUDGET_SHARED_TYPE_PROPS {
  HOUSEHOLD: BudgetType;
  USER: BudgetType;
}
export const BUDGET_SHARED_TYPE: BUDGET_SHARED_TYPE_PROPS = {
  HOUSEHOLD: "hh",
  USER: "user",
};

export const GOAL_STATUS = {
  ACTIVE: "ACTIVE",
  NEW: "NEW",
  PAUSED: "PAUSED",
  COMPLETED: "COMPLETED",
  CLOSED: "CLOSED",
  PFRAUD: "PFRAUD",
};

export const GOAL_FUNDING_ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  INVITED: "INVITED",
  SUSPENDED: "SUSPENDED",
  UNLINKED: "UNLINKED",
  PFRAUD: "PFRAUD",
};

export const NEW_GOAL_TYPES_2 = {
  OTHER: "OTHER",
  EMERGENCY: "EMERGENCY",
  VACATION: "VACATION",
  HOME: "HOME",
  WEDDING: "WEDDING",
  PAYOFF: "PAYOFF",
};
export const NEW_GOAL_TYPES = [
  {
    enum: "VACATION",
    category: "vacation",
    display: "Vacation",
    tagline: "Save for a vacation",
    isAsset: true,
    min: 500,
    max: 10000,
    step: 500,
    mostCommon: 2500,
    details: {},
    design: {
      textColor: "#03E776",
      bgColor: "#E0FAEC",
    },
  },
  {
    enum: "HOME",
    category: "home",
    display: "Home",
    tagline: "Save for a home",
    isAsset: true,
    min: 1000,
    max: 30000,
    step: 1000,
    mostCommon: 10000,
    details: {},
    design: {
      textColor: "#00B7D0",
      bgColor: "#E4F9FC",
    },
  },
  {
    enum: "EMERGENCY",
    category: "emergency",
    display: "Emergency fund",
    tagline: "Save for an emergency fund",
    isAsset: true,
    min: 500,
    max: 10000,
    step: 500,
    mostCommon: 1000,
    details: {},
    design: {
      textColor: "#FF1DA2",
      bgColor: "#FEE8F6",
    },
  },
  {
    enum: "WEDDING",
    category: "wedding",
    display: "Wedding",
    tagline: "Save for a wedding",
    isAsset: true,
    min: 500,
    max: 30000,
    step: 1000,
    mostCommon: 10000,
    details: {},
    design: {
      textColor: "#8685F9",
      bgColor: "#E4E1FF",
    },
  },
  {
    enum: "PAYOFF",
    category: "payoff",
    display: "Pay off debt",
    tagline: "Pay off debt",
    isAsset: false,
    min: 500,
    max: 10000,
    step: 500,
    mostCommon: 2000,
    details: {},
    design: {
      textColor: "#B8AF8F",
      bgColor: "#FAF5E2",
    },
  },
  {
    enum: "OTHER",
    category: "other",
    display: "Something else",
    tagline: "Save for something else",
    isAsset: true,
    min: 0,
    max: 10000,
    step: 500,
    mostCommon: 1000,
    details: {},
    design: {
      textColor: "#FF6539",
      bgColor: "#FFEAE6",
    },
  },
];

export const GOAL_RULES = [
  {
    name: "Payday",
    description: "Save a percentage each time you get paid",
    frequency: "ADHOC",
  },
  {
    name: "Weekly",
    description: "Save a set amount each week",
    frequency: "WEEKLY",
  },
  {
    name: "Bi-weekly",
    description: "Save a set amount every two weeks",
    frequency: "BIWEEKLY",
  },
];

export const GOAL_TRIGGER_TYPES = {
  SCHEDULE: "SCHEDULE",
  PAYDAY: "PAYDAY",
  ROUND_UP: "ROUND_UP",
  SPEND_TARGET: "SPEND_TARGET",
  SPEND_TAX: "SPEND_TAX",
};

export const GOAL_FREQUENCY_TYPES = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  BIWEEKLY: "BIWEEKLY",
  QUAD: "QUAD",
  SEMIMONTHLY: "SEMIMONTHLY",
  MONTHLY: "MONTHLY",
  BIMONTHLY: "BIMONTHLY",
  QUARTERLY: "QUARTERLY",
  ANNUALLY: "ANNUALLY",
  ADHOC: "ADHOC",
};

export const GOAL_FREQUENCY_DISPLAYS = {
  DAILY: "every day",
  WEEKLY: "every week",
  BIWEEKLY: "every two weeks",
  QUAD: "four times a month",
  SEMIMONTHLY: "twice a month",
  MONTHLY: "every month",
  BIMONTHLY: "every two months",
  QUARTERLY: "every quarter",
  ANNUALLY: "every year",
  ADHOC: "custom",
};

export const GOAL_CONSTANTS = {
  INTEREST_RATE: 0.0,
};

export const TRANSFER_STATUS = {
  INITIATED: "(pending)",
  PROCESSING: "(pending)",
  SETTLED: "",
  DECLINED: "(declined",
  VOIDED: "(cancelled)",
  SKIPPED: "(skipped",
};

export const TRANSACTION_SOURCE = {
  PLAID: "plaid",
  MANUAL: "manual",
  COREPRO: "corepro",
};

const constants = {
  APP_NAME: "Firstly",
  API_STATUS_CODES,
  BUDGET_DATE_FORMAT: "YYYY-MM-DD",
  NO_IMAGE: "No Image",
  MASTER_INVITE: "GDINV",
  FIRST_TIME_UX: 48, //hours since sign up,
  FIRST_WEEK_UX: 240, //hours since sign up. Don't show tips card for 4 days.
};

export const onboardingFlags = {
  NEW: "NEW",
  INVITE_PARTNER: "INVITE_PARTNER",
  ADD_ACCOUNTS_P1: "ADD_ACCOUNTS_P1",
  ADD_ACCOUNTS_P2: "ADD_ACCOUNTS_P2",
  SHARE_SETTINGS: "SHARE_SETTINGS",
  SET_SUBSCRIPTION: "SET_SUBSCRIPTION",
  FINISHED_ONBOARDING: "FINISHED_ONBOARDING",
};

export const TRANSACTION_DISPLAY = {
  SHARED: 0,
  ME: 1,
  PARTNER: 2,
  PRIVATE: 3,
};

export const AVATAR_DISPLAY = {
  SHARED: 0,
  ME: 1,
  PARTNER: 2,
  PRIVATE: 3,
};

export const TRANSACTION_VISIBILITY = {
  SHARED: "0",
  INDIVIDUAL: "1",
  IGNORE: "2",
};

interface BUDGET_SUPER_CATEGORIES_PROPS {
  EXPENSES: BudgetSuperCategory;
  INCOME: BudgetSuperCategory;
  IGNORE: BudgetSuperCategory;
}
export const BUDGET_SUPER_CATEGORIES: BUDGET_SUPER_CATEGORIES_PROPS = {
  EXPENSES: "Expenses",
  INCOME: "Income",
  IGNORE: "Ignore",
};

export const CATEGORY_CONSTANTS = {
  GOALS: "Goals",
  INCOME: "Income",
  IGNORE: "Ignore",
};

export const PAGE_URLS = {
  HOME: "/home",
  ACCTS_HOME: "/accounts",
  TRANS_HOME: "/transactions",
  TRANS_DETAILS: "/transactions/:transactionId",
  SPLIT_TRANS: "/transactions/:transactionId/split",
  OFFER_DETAILS: "/offers/:offerId",
  GOALS_HOME: "/goals",
  GOALS_DETAILS: "/goals/:goalId/detail",
  GOALS_NEW: "/goals/new",
  GOALS_NEW_NAME: "/goals/:goalId/new-goal-name",
  GOALS_FUNDING_SETUP_START: "/goals/:goalId/funding-setup-start",
  GOALS_FUNDING_SETUP_NAME: "/goals/:goalId/funding-setup-name",
  GOALS_FUNDING_CHOOSE_ACCOUNT: "/goals/:goalId/funding-setup-choose-account",
  GOALS_PARTNER_OVERVIEW: "/goals/:goalId/new-goal-partner-overview",
  GOALS_HOW_SAVE: "/goals/:goalId/new-goal-how-save",
  GOALS_FUNDING_PAYDAY: "/goals/:goalId/new-goal-payday-amount",
  GOALS_FUNDING_SCHEDULE: "/goals/:goalId/new-goal-schedule-amount",
  GOALS_INVITE_PARTNER: "/goals/:goalId/new-goal-invite-partner",
  GOALS_CELEBRATE_ACTIVATION: "/goals/:goalId/new-goal-celebrate-activation",
  GOALS_EDIT: "/goals/:goalId/edit",
  GOALS_TRANSACTIONS: "/goals/:goalId/transactions/:isUser",
  MORE_HOME: "/more",
  MORE_PROFILE: "/more/profile",
  MORE_PROFILE_INDIV_FINANCES: "/more/profile/individual-finances",
  MORE_PROFILE_INDIV_FINANCES_GOALS: "/more/profile/individual-finances/goals",
  MORE_PROFILE_SHARED_FINANCES: "/more/profile/shared-finances",
  MORE_PROFILE_SHARED_FINANCES_INSURANCE: "/more/profile/shared-finances/insurance",
  MORE_PROFILE_SHARED_FINANCES_HELP: "/more/profile/shared-finances/help",
  MORE_SETTINGS: "/more/settings",
  MORE_SETTINGS_CHANGE_PASSWORD: "/more/settings/change-password",
  MORE_SETTINGS_ACCOUNT: "/more/settings/manage-account",
  MORE_SETTINGS_ACCOUNT_SUBSCRIPTION: "/more/settings/manage-account/subscription",
  MORE_SETTINGS_NOTIFS: "/more/settings/notifications",
  MORE_SETTINGS_NOTIFS_EDIT: "/more/settings/notifications/edit/:notificationType",
  MORE_SETTINGS_NOTIFS_TIMING: "/more/settings/notificationsTiming",
  MORE_SETTINGS_RULES: "/more/settings/rules",
  MORE_SETTINGS_RULES_DETAIL: "/more/settings/rules/:ruleType/:sourceInstitutionId",
  MORE_MANAGE_ACCTS: "/more/manage-accounts",
  MORE_ACCTS_MANAGE_LINKED_LIST: "/more/manage-accounts/:sourceInstitutionId",
  MORE_ACCTS_MANAGE_LINKED_ACCT: "/more/manage-accounts/:sourceInstitutionId/:accountId",
  MORE_ACCTS_INSTITUTION_STATUS:
    "/more/manage-accounts/linked-institution-status/:sourceInstitutionId",
  MORE_ACCTS_INSTITUTION_UNLINK: "/more/manage-accounts/unlink/:sourceInstitutionId",
  MORE_GET_HELP: "/more/get-help",
  MORE_EXPORT_TXNS: "/more/export-transactions",
  MORE_AUTH_SETUP: "/more/auth-setup",
  MORE_POLICIES: "/more/policies",
  INVITE_PARTNER: "/more/invite-partner",
  BUDGET_HOME: "/home/budget/:effectiveDate/:budgetType",
  BUDGET_DETAILS: "/home/budget/:effectiveDate/:budgetType/:category/",
  CASH_FLOW: "/home/cashflow/:effectiveDate/:budgetType",
  BILLS: "/home/bills/:effectiveDate",
  BILLS_AUDIT_MAIN: "/home/billsaudit",
  BILLS_AUDIT_GET_STARTED: "/home/billsaudit/get-started",
  BILLS_AUDIT_CREATE_USER: "/home/billsaudit/create-user",
  BILLS_AUDIT_USER_ADDITIONAL_DATA: "/home/billsaudit/additional-user-data",
  BILLS_AUDIT_USER_PAYMENT_DETAILS: "/home/billsaudit/payment-details",
  BILLS_AUDIT_UPLOAD_DOCS: "/home/billsaudit/upload-docs",
  BILLS_AUDIT_DONE: "/home/billsaudit/complete",
  NET_WORTH: "/home/net-worth",
  SPENDING_FORECAST: "/home/spending-forecast",
  SPENDING_ANALYSIS: "/home/spending-analysis",
  RULES_MANAGE: "/rules/:accountId",
  RECENT_ACTIVITY: "/home/recent-activity",
  SPENDING_BY_PERSON_HOME: "/home/spending/:effectiveDate/:budgetType",
  SPENDING_CHALLENGES_NEW: "/spending-challenges/new",
  SPENDING_CHALLENGES_NEW_CATEGOTY: "/spending-challenges/new/category",
  SPENDING_CHALLENGES_NEW_LENGTH: "/spending-challenges/new/length",
  SPENDING_CHALLENGES_NEW_STACK: "/spending-challenges/new/stack",
  SPENDING_CHALLENGES_NEW_GOAL: "/spending-challenges/new/goal",
  SPENDING_CHALLENGES_HOME: "/spending-challenges",
  SPENDING_CHALLENGES_DETAILS: "/spending-challenges-detail/:challengeId",
  SPENDING_CHALLENGES_OVERVIEW: "/spending-challenges/overview",
  WELCOME: "/welcome",
  CLOSED: "/account-closed",
  LOGIN: "/login",
  FORGOT_PASS: "/forgot-password",
  FORGOT_PASS_SUCCESS: "/forgot-password-success",
  SIGNUP_HAS_PARTNER: "/signup/has-partner",
  SIGNUP_INVITE: "/signup/invite",
  SIGNUP_NAME: "/signup/name",
  SIGNUP_CONTACT: "/signup/contact",
  SIGNUP_PARTNER: "/signup/partner",
  SIGNUP_LINK_ACCTS: "/signup/link-accounts",
  SIGNUP_PARTNERS_LINKED_ACCTS: "/signup/partners-linked-accounts",
  SIGNUP_SHARE_SETTINGS: "/signup/share-settings",
  SIGNUP_START_TRIAL: "/signup/start-trial",
  LOGOUT: "/logout",
  ROOT: "/",
};

// if flag is FINISHED_ONBOARDING, don't allow them to go here
// handled in ProtectedRoute
export const SIGNUP_URLS = [
  PAGE_URLS.SIGNUP_HAS_PARTNER,
  PAGE_URLS.SIGNUP_INVITE,
  PAGE_URLS.SIGNUP_NAME,
  PAGE_URLS.SIGNUP_CONTACT,
  PAGE_URLS.SIGNUP_PARTNER,
  PAGE_URLS.SIGNUP_LINK_ACCTS,
  PAGE_URLS.SIGNUP_PARTNERS_LINKED_ACCTS,
  PAGE_URLS.SIGNUP_SHARE_SETTINGS,
  // PAGE_URLS.SIGNUP_START_TRIAL,
];

export const UNAUTHED_URLS = [
  ...SIGNUP_URLS,
  PAGE_URLS.WELCOME,
  PAGE_URLS.LOGIN,
  PAGE_URLS.FORGOT_PASS,
  PAGE_URLS.FORGOT_PASS_SUCCESS,
];

export const UNSUBBED_URLS = [
  ...UNAUTHED_URLS,
  PAGE_URLS.MORE_SETTINGS_ACCOUNT,
  PAGE_URLS.MORE_SETTINGS_ACCOUNT_SUBSCRIPTION,
];

export const EXTERNAL_URLS = {
  TERMS_AND_CONDITIONS: "https://firstly.com/terms-and-conditions/",
  PRIVACY_POLICY: "https://firstly.com/privacy-policy/",
  ABOUT: "https://firstly.com/about-firstly/",
  ARTICLES: "https://firstly.com/articles/",
  APP: "https://firstly.com/meet-your-family-financial-goals-with-the-firstly-budgeting-app/",
  MAIN: "https://firstly.com",
};

export const API_RESPONSE_TYPES = {
  SC_SUCCESS: "SC_SUCCESS",
  SC_ALREADY_EXISTS: "SC_ALREADY_EXISTS",
  SC_NOTFOUND: "SC_NOTFOUND",
  SC_ALREADY_INVITED: "SC_ALREADY_INVITED",
  SC_ALREADY_INVITED_AS_P2: "SC_ALREADY_INVITED_AS_P2",
  SC_SERVERERROR: "SC_SERVERERROR",
  SC_UNAUTHORIZED: "SC_UNAUTHORIZED",
  SC_REQUEST_FAILED: "SC_REQUEST_FAILED",
  SC_BAD_REQUEST: "SC_BAD_REQUEST",
  SC_MAIL_FAILED: "SC_MAIL_FAILED",
  SC_NO_ACCOUNT: "SC_NO_ACCOUNT",
  SC_INVALID_PASSWORD: "SC_INVALID_PASSWORD",
  SC_GENERIC_RESPONSE: "SC_GENERIC_RESPONSE",
  EC_INTERNAL_ERROR: "EC_INTERNAL_ERROR",
  EC_NOT_FOUND: "EC_NOT_FOUND",
  EC_BAD_REQUEST: "EC_BAD_REQUEST",
  EC_API_KEY_REQUIRED: "EC_API_KEY_REQUIRED",
  EC_INVALID_API_KEY: "EC_INVALID_API_KEY",
  EC_AUTH_TOKEN_REQUIRED: "EC_AUTH_TOKEN_REQUIRED",
  EC_AUTH_TOKEN_EXPIRED: "EC_AUTH_TOKEN_EXPIRED",
  EC_INVALID_AUTH_TOKEN: "EC_INVALID_AUTH_TOKEN",
  EC_INVALID_AUTH_CRED: "EC_INVALID_AUTH_CRED",
  EC_USER_DISABLED: "EC_USER_DISABLED",
  EC_ALREADY_REGISTERED: "EC_ALREADY_REGISTERED",
  EC_INVALID_OPERATION: "EC_INVALID_OPERATION",
  EC_NO_RESOURCE_ACCESS: "EC_NO_RESOURCE_ACCESS",
  EC_EMAIL_ALREADY_REGISTERED: "EC_EMAIL_ALREADY_REGISTERED",
  EC_PHONE_ALREADY_REGISTERED: "EC_PHONE_ALREADY_REGISTERED",
  EC_EMAIL_NOT_VERIFIED: "EC_EMAIL_NOT_VERIFIED",
  EC_PHONE_NOT_VERIFIED: "EC_PHONE_NOT_VERIFIED",
  EC_EMAIL_AND_PHONE_NOT_VERIFIED: "EC_EMAIL_AND_PHONE_NOT_VERIFIED",
  EC_ALREADY_CONNECTED: "EC_ALREADY_CONNECTED",
  EC_CONNECT_REQUEST_ALREADY_INCOMING: "EC_CONNECT_REQUEST_ALREADY_INCOMING",
  EC_NO_CONNECT_REQUESTED: "EC_NO_CONNECT_REQUESTED",
  EC_NO_CONNECT_TO_SELF: "EC_NO_CONNECT_TO_SELF",
  EC_NO_DISCONNECT_SELF: "EC_NO_DISCONNECT_SELF",
  EC_INVALID_FILE_TYPE: "EC_INVALID_FILE_TYPE",
  EC_FILE_SIZE_EXCEEDS_LIMIT: "EC_FILE_SIZE_EXCEEDS_LIMIT",
};

export const HOME_CARDS = {
  BUDGET: "budget",
  CASH_FLOW: "cash-flow",
  BILLS: "upcoming-bills",
  SPENDING_ANALYSIS: "spending-analysis",
  NET_WORTH: "net-worth",
  BILLS_AUDIT: "bills-audit",
  FUN_FACT: "fun-fact",
  SPENDING_BY_PERSON: "spending-by-person",
  ACTIVITY_FEED: "activity-feed",
  INCOME: "income",
  TOP_SUBCATS: "top-subcats",
  SPENDING_CHALLENGES: "spending-challenges",
  WELCOME: "welcome",
  FORECAST: "forecast",
};

export const ALL_HOME_CARDS_ARRAY = [
  HOME_CARDS.WELCOME,
  HOME_CARDS.BUDGET,
  HOME_CARDS.CASH_FLOW,
  HOME_CARDS.BILLS,
  HOME_CARDS.INCOME,
  HOME_CARDS.FUN_FACT,
  HOME_CARDS.ACTIVITY_FEED,
  HOME_CARDS.SPENDING_BY_PERSON,
  HOME_CARDS.BILLS_AUDIT,
  HOME_CARDS.SPENDING_CHALLENGES,
  HOME_CARDS.NET_WORTH,
  HOME_CARDS.FORECAST,
  HOME_CARDS.SPENDING_ANALYSIS,
  HOME_CARDS.TOP_SUBCATS,
];

type BillFrequencyType = {
  val: BillFrequency;
  disp: string;
};

export const FEEDS_TYPE: tplotOptions = {
  0: "CATEGORY_CHANGE",
  1: "TRANSACTION_COMMMENTED",
  2: "TRANSACTION_REACTION",
  3: "ACCOUNT_SHARED",
  4: "ACCOUNT_VISIBLE",
  5: "ACCOUNT_JUSTME",
  6: "ACCOUNT_IGNORE",
  7: "TRANSACTION_SHARED",
  8: "TRANSACTION_UNSHARED",
  9: "ACCOUNT_ADD",
  10: "BUDGET_EDITED",
  11: "BUDGET_EDITED_DEFAULT",
  12: "RULE_CHANGE",
  13: "GOAL_ADDED",
  14: "GOAL_COMMENTED",
  15: "SPENDING_CHALLENGE_ADDED",
  16: "SPENDING_CHALLENGE_COMMENTED",
  17: "GOAL_MILESTONE",
  18: "GOAL_TRANSFER",
  19: "BUDGET_MILESTONE",
  20: "ACCOUNT_ADD_SET_SHARE",
  21: "ACCOUNT_ALERT",
};

type tplotOptions = {
  [key: string]: string;
};

export const FEEDS_ICON: tplotOptions = {
  0: "bookmarkOutline",
  1: "chatbubbles",
  2: "chatbubbles",
  3: "idCardOutline",
  4: "idCardOutline",
  5: "idCardOutline",
  6: "idCardOutline",
  7: "swapHorizontalOutline",
  8: "swapHorizontalOutline",
  9: "idCardOutline",
  10: "bookmarkOutline",
  11: "bookmarkOutline",
  12: "bookOutline",
  13: "discOutline",
  14: "discOutline",
  15: "trophyOutline",
  16: "trophyOutline",
  17: "discOutline",
  18: "discOutline",
  19: "bookmarkOutline",
  20: "idCardOutline",
  21: "idCardOutline",
};

export const BILLS_FREQUENCY_TYPES: BillFrequencyType[] = [
  // { val: "weekly", disp: "Every week" },
  // { val: "biweekly", disp: "Every 2 weeks" },
  {
    val: "monthly",
    disp: "Every month",
  },
  {
    val: "bimonthly",
    disp: "Every 2 months",
  },
  {
    val: "quarterly",
    disp: "Every 3 months",
  },
  {
    val: "semiannually",
    disp: "Every 6 months",
  },
  {
    val: "annually",
    disp: "Every year",
  },
  {
    val: "adhoc",
    disp: "One time only",
  },
];

type BillReminderOptions = {
  val: BillReminderDaysOptions;
  disp: string;
};

export const BILL_REMINDER_OPTIONS: BillReminderOptions[] = [
  {
    val: 0,
    disp: "On due date",
  },
  {
    val: 1,
    disp: "1 day before due",
  },
  {
    val: 3,
    disp: "3 days before due",
  },
  {
    val: 5,
    disp: "5 days before due",
  },
  {
    val: 7,
    disp: "7 days before due",
  },
  {
    val: false,
    disp: "Don't send reminder",
  },
];

type BillsSharedStatus = {
  [dynamic: string]: BillSharedStatus;
};
export const BILLS_SHARED_STATUS: BillsSharedStatus = {
  SHARED: "shared",
  ME: "mine",
  PARTNER: "theirs",
  PRIVATE: "private",
};

export enum S3_URL_TYPES {
  GET_OBJECT = "getObject",
  PUT_OBJECT = "putObject",
}

export enum S3_DOC_TYPES {
  USER = "user",
  HH = "hh",
  BILLSHARK = "billsharkBills",
  OTHER = "other",
}

export const US_STATES = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const NET_WORTH_FACTOR = {
  netWorth: "Net Worth",
  assets: "Assets",
  debts: "Debts",
};

export const RULE_FIELD_NAMES_DISPLAY = {
  honeyficategoryname: {
    long: "Subcategory",
    short: "subcat",
  },
  beefcategoryname: {
    long: "Category",
    short: "cat",
  },
  "description.original": {
    long: "merchant",
  },
  "description.simple": {
    long: "merchant",
  },
};

export const MODAL_TYPES = {
  SUBSCRIPTION_SUCCESS: "SUBSCRIPTION_SUCCESS",
  OFFER_CODE_SUCCESS: "OFFER_CODE_SUCCESS",
  "": "",
};
export default constants;
