import React, { useState } from "react";
import { IonItem, IonInput, IonLabel, IonText, IonButton, IonFooter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../shared/PageTemplate";
import { useDispatch, useSelector } from "../../../../redux/hooks";
import { getUsersState } from "../../../../redux/selectors";
import { editUser } from "../../../../redux/actions";
import "./CloseAccount.scss";
import { trackEvent } from "../../../../vendors/monitoring";
import { closeStep } from "./CloseAccountModal";

export const TellUsMore = (props: any) => {
  const { reason, setStep, nextPage } = props;
  const [explanation, setExplanation] = useState<string>("");
  const dispatch = useDispatch();
  const userData = useSelector(getUsersState);
  const { t } = useTranslation();

  const setClosingReason = () => {
    dispatch(
      editUser({
        ...userData,
        closeReason: {
          reason: reason,
          reasonDetail: explanation,
        },
      })
    );
    trackEvent("update_closing_reason", { category: "settings" });
    setStep(closeStep.STEP3);
  };
  return (
    <>
      <Section className="close-account" data-testid="closeAccount_explain">
        <IonText className="close-account-margin" data-testid="closeAccount_reason_desc">
          <h2 className="font-title-3 semibold">{t("closeAccount_reason_title")}</h2>
          <p className="medium">{t(`closeAccount_reason_more_${reason}`)}</p>
        </IonText>
        <IonItem className="close-account-explain">
          <IonLabel position="stacked" className="font-sm">
            {t("closeAccount_explain")}
          </IonLabel>
          <IonInput
            inputmode="text"
            className="ion-text-wrap"
            data-testid="closeAccount_explain_field"
            placeholder={t(`closeAccount_explain_${reason}`)}
            onIonChange={(e) => setExplanation(e.detail.value!)}
          ></IonInput>
        </IonItem>
      </Section>
      <IonFooter className="close-account-footer ion-text-center ion-padding fy-section-card ion-no-border">
        <IonButton
          data-testid="closeAccount_explain_skip"
          fill="clear"
          onClick={() => {
            setStep(nextPage());
          }}
        >
          {t("skip")}
        </IonButton>
        <IonButton
          data-testid="closeAccount_explain_next"
          type="submit"
          disabled={explanation.length === 0}
          onClick={setClosingReason}
        >
          {t("next")}
        </IonButton>
      </IonFooter>
    </>
  );
};
