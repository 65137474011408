import React, { useEffect, useState } from "react";
import { IonItem, IonItemDivider, IonLabel, IonList, IonText, IonAlert } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceDataState } from "../../../redux/selectors";
import { checkToAskForPushPermissions } from "../../../utils/pushUtils";
import { DeviceType } from "../../../redux/reducers/deviceData";
import { HomeCardManagementModal } from "../Home/HomeCardsManagement";
import { EditCategoriesModal } from "../Budget/EditCategoriesModal";
import { get, post } from "../../../utils/apiOps";
import dayjs from "dayjs";
import { getBudget, setLoading } from "../../../redux/actions";
import { ActionSheet, PageTemplate, Section, NavHeader } from "../../shared/PageTemplate";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio";
import { trackEvent } from "../../../vendors/monitoring";

enum ResetBudgetAlertType {
  SCRATCH = "scratch",
  CALCULATE = "calculate",
  NONE = "",
}

export const Settings: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { deviceDetails, localPermissionHistory }: DeviceType = useSelector(getDeviceDataState);
  const [pushGranted, setPushGranted] = useState<boolean>(false);
  const [showManageCardsModal, setShowManageCardsModal] = useState<boolean>(false);
  const [showEditCategorizationModal, setShowEditCategorizationModal] = useState(false);
  const [showResetBudgetEvent, setShowResetBudgetEvent] = useState<Event>();
  const [showResetBudgetAlert, setShowResetBudgetAlert] = useState(ResetBudgetAlertType.NONE);
  const [fpIsAvailable, setFpIsAvailable] = useState(false);

  const pushPermissionCheck = () => {
    if (
      deviceDetails &&
      deviceDetails.platform !== "web" &&
      localPermissionHistory &&
      !localPermissionHistory.hardPermissionGranted
    ) {
      setPushGranted(false);
    } else {
      setPushGranted(true);
    }
  };

  const askToGrantPermissions = () => {
    dispatch(checkToAskForPushPermissions(true));
  };

  useEffect(() => {
    pushPermissionCheck();
    FingerprintAIO.isAvailable()
      .then(() => setFpIsAvailable(true))
      .catch((err) => console.debug("FingerprintAIO not available", err));
  }, []);

  return (
    <PageTemplate
      contentProps={{ fullscreen: true }}
      header={<NavHeader defaultHref={PAGE_URLS.MORE_HOME} title={t("settings")} />}
    >
      <Section>
        <IonList lines="none">
          <IonItemDivider>
            <IonLabel>{t("security")}</IonLabel>
          </IonItemDivider>
          <IonItem
            routerLink={PAGE_URLS.MORE_SETTINGS_CHANGE_PASSWORD}
            detail={true}
            data-testid="ChangePassword"
          >
            <IonLabel>{t("changePassword")}</IonLabel>
          </IonItem>
          {fpIsAvailable && (
            <IonItem routerLink={PAGE_URLS.MORE_AUTH_SETUP} detail={true}>
              <IonLabel>
                {deviceDetails?.platform === "ios" ? t("faceId") : t("fingerprintAuth")}
              </IonLabel>
            </IonItem>
          )}
          <IonItemDivider>
            <IonLabel>{t("notifications")}</IonLabel>
          </IonItemDivider>
          {
            <IonItem
              routerLink={PAGE_URLS.MORE_SETTINGS_NOTIFS}
              data-testid="ManageNotifications"
              detail={true}
            >
              <IonLabel>{t("notificationsManage")}</IonLabel>
            </IonItem>
          }
          {!pushGranted && (
            <IonItem onClick={askToGrantPermissions} detail={false}>
              <IonLabel color="danger">{t("notificationsNotGranted")}</IonLabel>
              <IonText>{t("notificationsGrant")}</IonText>
            </IonItem>
          )}
          <IonItemDivider>
            <IonLabel>{t("actions")}</IonLabel>
          </IonItemDivider>
          <IonItem onClick={() => setShowEditCategorizationModal(true)} detail={true}>
            <IonLabel>{t("editCats")}</IonLabel>
          </IonItem>
          <IonItem
            onClick={(e) => {
              e.persist();
              setShowResetBudgetEvent(e as any);
            }}
            detail={true}
          >
            <IonLabel>{t("resetBudget")}</IonLabel>
          </IonItem>
          <IonItem
            routerLink={PAGE_URLS.MORE_EXPORT_TXNS}
            data-testid="settings_exportTransactions"
            detail={true}
          >
            <IonLabel>{t("exportTransactions")}</IonLabel>
          </IonItem>
          <IonItem
            onClick={() => setShowManageCardsModal(true)}
            data-testid="settings_manageCards"
            detail={true}
          >
            <IonLabel>{t("manageHomeCards")}</IonLabel>
          </IonItem>
          <IonItem routerLink={PAGE_URLS.MORE_SETTINGS_RULES} detail={true}>
            <IonLabel>{t("manageRules")}</IonLabel>
          </IonItem>
          <IonItem
            routerLink={PAGE_URLS.MORE_SETTINGS_ACCOUNT}
            detail={true}
            data-testid="settings_manageAccount"
          >
            <IonLabel>{t("manageAccount")}</IonLabel>
          </IonItem>
        </IonList>
      </Section>
      <HomeCardManagementModal
        isOpen={showManageCardsModal}
        onClose={() => setShowManageCardsModal(false)}
      />
      <EditCategoriesModal
        isOpen={showEditCategorizationModal}
        onClose={() => setShowEditCategorizationModal(false)}
      />
      <ActionSheet
        openEvent={showResetBudgetEvent}
        onDidDismiss={setShowResetBudgetEvent as () => void}
        header={t("resetBudget")}
        hideButton
        buttons={[
          {
            text: t("resetBudgetFromScratch"),
            handler: () => setShowResetBudgetAlert(ResetBudgetAlertType.SCRATCH),
          },
          {
            text: t("resetBudgetCalculate"),
            handler: () => setShowResetBudgetAlert(ResetBudgetAlertType.CALCULATE),
          },
          {
            text: t("cancel"),
            role: "cancel",
          },
        ]}
      />
      <IonAlert
        isOpen={showResetBudgetAlert !== ResetBudgetAlertType.NONE}
        onDidDismiss={() => setShowResetBudgetAlert(ResetBudgetAlertType.NONE)}
        header={t("alertSureTitle")}
        message={t(
          showResetBudgetAlert === ResetBudgetAlertType.SCRATCH
            ? "resetBudgetScratchWarning"
            : "resetBudgetCalculateWarning"
        )}
        buttons={[
          {
            text: t("no"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("yes"),
            role: "destructive",
            handler: () => {
              dispatch(setLoading(true));
              if (showResetBudgetAlert === ResetBudgetAlertType.SCRATCH) {
                post(true, {
                  endpoint: "/budget/zero",
                  bodyData: {
                    effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD"),
                    acceptedBudget: true,
                  },
                })
                  .then(() => {
                    dispatch(
                      getBudget({
                        effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD"),
                        forceRefresh: true,
                      })
                    );
                  })
                  .catch()
                  .finally(() => {
                    trackEvent("resetBudget_fromScratch", { category: "resetBudget" });
                    dispatch(setLoading(false));
                  });
              } else {
                get(true, {
                  endpoint: "/budget/calculate",
                  params: {
                    makeActive: true,
                    type: "hh",
                  },
                })
                  .then(() => {
                    dispatch(
                      getBudget({
                        effectiveDate: dayjs().startOf("month").format("YYYY-MM-DD"),
                        forceRefresh: true,
                      })
                    );
                  })
                  .catch()
                  .finally(() => {
                    trackEvent("resetBudget_recalculate", { category: "resetBudget" });
                    dispatch(setLoading(false));
                  });
              }
            },
          },
        ]}
      />
    </PageTemplate>
  );
};
