import React from "react";
import { IonItem, IonLabel, IonText, IonButton, IonFooter, IonInput } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../redux/hooks";
import { Section } from "../../../shared/PageTemplate";
import "./CloseAccount.scss";
import { getGoalsBalance } from "../../../../utils/goalUtils";
import numeral from "numeral";
import { closeStep } from "./CloseAccountModal";
import { formatAccountNumber } from "../../../../utils/formatUtils";
import { getFundingAccount, getSubscriptionState } from "../../../../redux/selectors";

export const GoalBalances = (props: any) => {
  const { setStep, allBalances } = props;
  const { t } = useTranslation();
  const { subscription } = useSelector(getSubscriptionState);
  const goalsBalance = numeral(getGoalsBalance(allBalances.hh));
  const fundingAccount = useSelector(getFundingAccount);

  const nextPage = (): string => {
    let next = closeStep.STEP1;
    const active = subscription?.activeSub;

    if (active) {
      next = closeStep.STEP5_B;
    } else {
      next = closeStep.STEP5_A;
    }

    return next;
  };

  return (
    <>
      <Section className="close-account" data-testid="closeAccount_explain">
        <IonText className="close-account-margin font-title-3 semibold">
          <h2 className="font-title-3 semibold">{t("closeAccount_balances_header")}</h2>
        </IonText>
        <IonText className="close-account-margin font-md">
          <p className="font-md">{t("closeAccount_balances_desc")}</p>
        </IonText>

        <IonItem
          className="close-account-balance-item text-faded"
          data-testid="closeAccount_fundingAccount"
        >
          <IonLabel position="stacked">{t("account")}</IonLabel>
          <IonInput readonly>
            <h2 className="ion-no-margin font-md text-margins text-no-wrap">
              {fundingAccount?.accountName}
            </h2>
            <p className="ion-no-margin font-sm text-margins">
              {formatAccountNumber(fundingAccount?.accountNumber as string)}
            </p>
          </IonInput>
        </IonItem>
        <IonItem
          className="close-account-balance-item text-faded"
          data-testid="closeAccount_goalBalance"
        >
          <IonLabel position="stacked">{t("closeAccount_balances_availableBalance")}</IonLabel>
          <IonInput value={goalsBalance.format("$0,0.00")} readonly></IonInput>
        </IonItem>

        <IonText className="font-xs font-italic close-account-margin close-account-balance-footnote margin-top-50">
          <p>{t("closeAccount_balances_footNote")}</p>
        </IonText>
      </Section>
      <IonFooter className="close-account-footer ion-text-center ion-padding fy-section-card ion-no-border">
        <IonButton
          type="submit"
          data-testid="closeAccount_keepBenefits_close"
          className="semibold"
          onClick={() => {
            setStep(nextPage());
          }}
        >
          {t("next")}
        </IonButton>
      </IonFooter>
    </>
  );
};
