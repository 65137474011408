import { SET_LOADING, SET_TOAST_MESSAGE, OPEN_MODAL } from "../actionTypes";
import { MODAL_TYPES } from "../../constants";

const initialState: uiState = {
  loading: false,
  toastMessage: "",
  modal: {
    type: "",
    props: {},
  },
};

interface uiState {
  loading: boolean;
  toastMessage: string;
  modal: {
    type: keyof typeof MODAL_TYPES;
    props: any;
  };
}

interface action {
  type: string;
  payload: {
    loading: boolean;
    toastMessage: string;
    modal: string;
    props: string;
  };
}

const ui = (state: uiState = initialState, action: action) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }

    case SET_TOAST_MESSAGE: {
      return {
        ...state,
        toastMessage: action.payload.toastMessage,
      };
    }

    case OPEN_MODAL: {
      return {
        ...state,
        modal: {
          type: action.payload.modal,
          props: action.payload.props,
        },
      } as uiState;
    }

    default: {
      return state;
    }
  }
};

export default ui;
