import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";
import {
  HomeTab,
  AccountsTab,
  TransactionsTab,
  TransactionDetail,
  SplitTransaction,
  GoalsTab,
  MoreTab,
  LinkedInstitutionStatus,
  ManageLinkedInstitutionsList,
  ManageLinkedAccountsList,
  Settings,
  ManageLinkedAccount,
  NotificationPreference,
  BudgetCategoryDetail,
  BudgetSubcategoryDetail,
  CashFlowDetail,
  BillsDetail,
} from "..";
import ProtectedRoute from "../../shared/ProtectedRoute/ProtectedRoute";
import { Notifications } from "../MoreTab/Notifications";
import { ExportTransactions } from "../MoreTab/Actions/ExportTransactions";
import { PAGE_URLS } from "../../../constants";
import "./NavTabs.scss";
import Avatar from "../../shared/Avatar/Avatar";
import { GoalDetail } from "../GoalsTab/GoalDetail";
import { NewGoalPage } from "../GoalsTab/NewGoalPage";
import { NewGoalName } from "../GoalsTab/NewGoalName";
import { HowToSave } from "../GoalsTab/HowToSave";
import { PaydayFunding } from "../GoalsTab/PaydayFunding";
import { FundingSetupStart } from "../GoalsTab/FundingSetupStart";
import { FundingSetupName } from "../GoalsTab/FundingSetupName";
import { FundingChooseAccount } from "../GoalsTab/FundingChooseAccount";
import { ScheduleFunding } from "../GoalsTab/ScheduleFunding";
import { CelebrateActivation } from "../GoalsTab/CelebrateActivation";
import { InvitePartner } from "../GoalsTab/InvitePartner";
import { RecentActivitiesDetail } from "../Budget/RecentActivitiesDetail";
import { SpendingByPersonDetail } from "../Budget/SpendingByPersonDetail";
import { Help } from "../MoreTab/Help";
import { BillsAuditMain } from "../BillsAudit/BillsAuditMain";
import { EditGoal } from "../GoalsTab/EditGoal";
import { GoalTransactions } from "../GoalsTab/GoalTransactions";
import { ManageRules } from "../MoreTab/ManageRules";
import { ManageRulesDetail } from "../MoreTab/ManageRulesDetail";
import { NewSpendingChallenge } from "../SpendingChallenge/NewSpendingChallenge";
import { NewSpendingChallengeCategory } from "../SpendingChallenge/NewSpendingChallengeCategory";
import { NewSpendingChallengeLength } from "../SpendingChallenge/NewSpendingChallengeLength";
import { NewSpendingChalllengeStack } from "../SpendingChallenge/NewSpendingChalllengeStack";
import { NewSpendingChalllengeGoal } from "../SpendingChallenge/NewSpendingChallengeGoal";
import { BillsAuditCreateUser } from "../BillsAudit/BillsAuditCreateUser";
import { BillsAuditGetStarted } from "../BillsAudit/BillsAuditGetStarted";
import { BillsAuditEnterPayment } from "../BillsAudit/BillsAuditEnterPayment";
import { BillsAuditUploadDocs } from "../BillsAudit/BillsAuditUploadDocs";
import { BillsAuditAdditionalData } from "../BillsAudit/BillsAuditAdditionalData";
import { BillsAuditCelebrate } from "../BillsAudit/BillsAuditCelebrate";
import { ManageUserAccount } from "../MoreTab/ManageUserAccount";
import { NetWorthPage } from "../NetWorth/NetWorth";
import { ProfilePage } from "../MoreTab/ProfilePage";
import { SharedFinances } from "../MoreTab/ProfileScreens/SharedFinances";
import { IndivFinances } from "../MoreTab/ProfileScreens/IndivFinances";
import { IndivGoals } from "../MoreTab/ProfileScreens/IndivGoals";
import { SharedFinancesInsurance } from "../MoreTab/ProfileScreens/SharedFinancesInsurance";
import { SharedFinancesHelp } from "../MoreTab/ProfileScreens/SharedFinancesHelp";
import { PartnerOverview } from "../GoalsTab/PartnerOverview";
import { ChangePassword } from "../MoreTab/Security/ChangePassword";
import { SpendingChallengeDetail } from "../SpendingChallenge/SpendingChallengeDetail";
import { SpendingForecastPage } from "../Forecast/SpendingForecastPage";
import { SpendingChallengeOverview } from "../SpendingChallenge/SpendingChallengeOverview";
import { SpendingAnalysisPage } from "../SpendingAnalysis/SpendingAnalysisPage";
import { UnlinkInstitution } from "../MoreTab/UnlinkInstitution";
import { Policies } from "../MoreTab/Policies";
import { OfferDetails } from "../Offers/OfferDetails";
import { InvitePartnerPage } from "../MoreTab/InvitePartnerPage";
import { tabLinks } from "../../../utils/routeUtils";
import { FingerprintAuthSetup } from "../MoreTab/Security/FingerprintAuthSetup";

import { trackEvent } from "../../../vendors/monitoring";

import { titleCase } from "../../../utils/formatUtils";
import { ManageSubscriptionPage } from "../MoreTab/Subscription";

export const NavTabs: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isActive = (url: string) => location.pathname.startsWith(url);
  return (
    <IonTabs className="tabs-container">
      <IonRouterOutlet ionPage>
        <ProtectedRoute path={PAGE_URLS.HOME} component={HomeTab} exact={true} />
        <ProtectedRoute
          path={PAGE_URLS.BUDGET_HOME}
          component={BudgetCategoryDetail}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.BUDGET_DETAILS}
          component={BudgetSubcategoryDetail}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_BY_PERSON_HOME}
          component={SpendingByPersonDetail}
          exact
        />
        <ProtectedRoute path={PAGE_URLS.CASH_FLOW} component={CashFlowDetail} exact={true} />
        <ProtectedRoute path={PAGE_URLS.BILLS} component={BillsDetail} exact={true} />
        <ProtectedRoute path={PAGE_URLS.NET_WORTH} component={NetWorthPage} exact={true} />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_FORECAST}
          component={SpendingForecastPage}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_ANALYSIS}
          component={SpendingAnalysisPage}
          exact={true}
        />
        <ProtectedRoute path={PAGE_URLS.ACCTS_HOME} component={AccountsTab} exact={true} />
        <ProtectedRoute path={PAGE_URLS.TRANS_HOME} component={TransactionsTab} exact={true} />
        <ProtectedRoute path={PAGE_URLS.OFFER_DETAILS} component={OfferDetails} />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_CHALLENGES_DETAILS}
          component={SpendingChallengeDetail}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_CHALLENGES_OVERVIEW}
          component={SpendingChallengeOverview}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_CHALLENGES_NEW}
          component={NewSpendingChallenge}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_CHALLENGES_NEW_CATEGOTY}
          component={NewSpendingChallengeCategory}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_CHALLENGES_NEW_LENGTH}
          component={NewSpendingChallengeLength}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_CHALLENGES_NEW_STACK}
          component={NewSpendingChalllengeStack}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.SPENDING_CHALLENGES_NEW_GOAL}
          component={NewSpendingChalllengeGoal}
          exact
        />
        <ProtectedRoute path={PAGE_URLS.GOALS_NEW} component={NewGoalPage} exact />
        <ProtectedRoute path={PAGE_URLS.GOALS_NEW_NAME} component={NewGoalName} exact />
        <ProtectedRoute path={PAGE_URLS.GOALS_HOW_SAVE} component={HowToSave} exact />
        <ProtectedRoute
          path={PAGE_URLS.GOALS_FUNDING_SETUP_START}
          component={FundingSetupStart}
          exact
        />
        <ProtectedRoute path={PAGE_URLS.RECENT_ACTIVITY} component={RecentActivitiesDetail} exact />
        <ProtectedRoute
          path={PAGE_URLS.GOALS_FUNDING_SETUP_NAME}
          component={FundingSetupName}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.GOALS_FUNDING_CHOOSE_ACCOUNT}
          component={FundingChooseAccount}
          exact
        />
        <ProtectedRoute path={PAGE_URLS.GOALS_FUNDING_PAYDAY} component={PaydayFunding} exact />
        <ProtectedRoute path={PAGE_URLS.GOALS_FUNDING_SCHEDULE} component={ScheduleFunding} exact />
        <ProtectedRoute path={PAGE_URLS.GOALS_INVITE_PARTNER} component={InvitePartner} exact />
        <ProtectedRoute
          path={PAGE_URLS.GOALS_CELEBRATE_ACTIVATION}
          component={CelebrateActivation}
          exact
        />
        <ProtectedRoute path={PAGE_URLS.GOALS_PARTNER_OVERVIEW} component={PartnerOverview} exact />
        <ProtectedRoute path={PAGE_URLS.GOALS_DETAILS} component={GoalDetail} exact />
        <ProtectedRoute path={PAGE_URLS.GOALS_EDIT} component={EditGoal} exact />
        <ProtectedRoute path={PAGE_URLS.GOALS_TRANSACTIONS} component={GoalTransactions} exact />
        <ProtectedRoute path={PAGE_URLS.GOALS_HOME} component={GoalsTab} exact />
        <ProtectedRoute path={PAGE_URLS.TRANS_DETAILS} component={TransactionDetail} exact={true} />
        <ProtectedRoute path={PAGE_URLS.SPLIT_TRANS} component={SplitTransaction} exact={true} />
        <ProtectedRoute path={PAGE_URLS.MORE_HOME} component={MoreTab} exact={true} />
        <ProtectedRoute
          path={PAGE_URLS.MORE_PROFILE}
          component={ProfilePage}
          exact
          data-testid="more_profile"
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_PROFILE_INDIV_FINANCES}
          component={IndivFinances}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_PROFILE_INDIV_FINANCES_GOALS}
          component={IndivGoals}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_PROFILE_SHARED_FINANCES}
          component={SharedFinances}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_PROFILE_SHARED_FINANCES_INSURANCE}
          component={SharedFinancesInsurance}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_PROFILE_SHARED_FINANCES_HELP}
          component={SharedFinancesHelp}
          exact
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_SETTINGS}
          component={Settings}
          exact={true}
          data-testid="more_settings"
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_SETTINGS_NOTIFS}
          component={Notifications}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_SETTINGS_CHANGE_PASSWORD}
          component={ChangePassword}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_SETTINGS_NOTIFS_EDIT}
          component={NotificationPreference}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_MANAGE_ACCTS}
          component={ManageLinkedInstitutionsList}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_EXPORT_TXNS}
          component={ExportTransactions}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_AUTH_SETUP}
          component={FingerprintAuthSetup}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_ACCTS_MANAGE_LINKED_LIST}
          component={ManageLinkedAccountsList}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_ACCTS_MANAGE_LINKED_ACCT}
          component={ManageLinkedAccount}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_ACCTS_INSTITUTION_STATUS}
          component={LinkedInstitutionStatus}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_ACCTS_INSTITUTION_UNLINK}
          component={UnlinkInstitution}
          exact={true}
        />
        <ProtectedRoute path={PAGE_URLS.MORE_SETTINGS_RULES} component={ManageRules} exact={true} />
        <ProtectedRoute
          path={PAGE_URLS.MORE_SETTINGS_RULES_DETAIL}
          component={ManageRulesDetail}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_SETTINGS_ACCOUNT}
          component={ManageUserAccount}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.MORE_SETTINGS_ACCOUNT_SUBSCRIPTION}
          component={ManageSubscriptionPage}
          exact
        />
        <ProtectedRoute path={PAGE_URLS.MORE_GET_HELP} component={Help} exact={true} />
        <ProtectedRoute path={PAGE_URLS.MORE_POLICIES} component={Policies} />
        <ProtectedRoute path={PAGE_URLS.BILLS_AUDIT_MAIN} component={BillsAuditMain} exact={true} />
        <ProtectedRoute
          path={PAGE_URLS.BILLS_AUDIT_GET_STARTED}
          component={BillsAuditGetStarted}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.BILLS_AUDIT_CREATE_USER}
          component={BillsAuditCreateUser}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.BILLS_AUDIT_USER_PAYMENT_DETAILS}
          component={BillsAuditEnterPayment}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.BILLS_AUDIT_UPLOAD_DOCS}
          component={BillsAuditUploadDocs}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.BILLS_AUDIT_USER_ADDITIONAL_DATA}
          component={BillsAuditAdditionalData}
          exact={true}
        />
        <ProtectedRoute
          path={PAGE_URLS.BILLS_AUDIT_DONE}
          component={BillsAuditCelebrate}
          exact={true}
        />
        <ProtectedRoute path={PAGE_URLS.INVITE_PARTNER} component={InvitePartnerPage} exact />
        <Route exact path="/">
          <Redirect to={PAGE_URLS.HOME} />
        </Route>
        <Route render={() => <Redirect to={PAGE_URLS.HOME} />} />
      </IonRouterOutlet>
      <IonTabBar
        onIonTabsWillChange={({ detail }) => {
          if (typeof detail?.tab === "string") {
            trackEvent(`click_nav${titleCase(detail?.tab)}`, { category: "navigation" });
          }
        }}
        slot="bottom"
        className="tabs-bar"
      >
        {tabLinks.map((link) => (
          <IonTabButton
            key={link.name}
            tab={link.name}
            href={link.url}
            data-testid={`${link.name}Tab`}
            style={{ "--tab-theme-color": link.color }}
          >
            {link.name === "more" ? (
              <Avatar isUser />
            ) : (
              <IonIcon src={isActive(link.url) ? link.icon_selected : link.icon} />
            )}
            <IonLabel>{t(`tab_${link.name}`)}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};
