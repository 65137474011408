import React, { useState } from "react";
import {
  IonItem,
  IonInput,
  IonLabel,
  IonText,
  IonButton,
  IonFooter,
  IonIcon,
  useIonAlert,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { createOutline } from "ionicons/icons";
import { Section } from "../../../shared/PageTemplate";
import { useDispatch } from "../../../../redux/hooks";
import { closeAccount } from "../../../../redux/actions";

import "./CloseAccount.scss";
import { PAGE_URLS } from "../../../../constants";
import { trackEvent } from "../../../../vendors/monitoring";

export const ConfirmClose = (props: any) => {
  const { setModalOn } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [password, setPassword] = useState<string>("");
  const [present] = useIonAlert();

  const handleGoalBalanceNotice = () => {
    present({
      header: t("closeAccount_balances_header"),
      message: t("closeAccount_closureStarted"),
      buttons: [
        {
          text: t("gotIt"),
          role: "okay",
          handler: () => {
            history.replace(PAGE_URLS.CLOSED);
          },
        },
      ],
    });
  };

  return (
    <>
      <Section className="close-account">
        <IonText className="close-account-margin">
          <h2 className="font-title-3 semibold">{t("closeAccount")}</h2>
          <p className="medium">{t("closeAccount_confirm")}</p>
        </IonText>
        <IonItem className="close-account-explain">
          <IonLabel position="stacked" className="ion-text-small">
            {t("password")}
          </IonLabel>
          <IonInput
            inputmode="text"
            type="password"
            pattern="password"
            className="ion-text-wrap"
            placeholder={t("yourPassword")}
            onIonChange={(e) => setPassword(e.detail.value!)}
            data-testid="closeAccount_password_field"
          ></IonInput>
          <IonIcon className="ion-align-self-end font-md" icon={createOutline} slot="end" />
        </IonItem>
        <IonButton
          size="small"
          color="secondary"
          fill="clear"
          data-testid="forgot-pass"
          routerLink={PAGE_URLS.FORGOT_PASS}
          onClick={() => trackEvent("click_forgotPassword", { category: "onboard" })}
          className="ion-margin-top ion-margin-start"
        >
          {t("forgotPassword")}
        </IonButton>
      </Section>
      <IonFooter className="close-account-footer ion-text-center ion-padding fy-section-card ion-no-border">
        <IonButton
          type="submit"
          fill="clear"
          disabled={password === ""}
          onClick={() =>
            present({
              header: t("closeAccount"),
              message: t("closeAccountWarning"),
              buttons: [
                {
                  text: t("cancel"),
                  role: "cancel",
                  cssClass: "secondary",
                },
                {
                  text: t("delete"),
                  role: "destructive",
                  handler: () => {
                    dispatch(closeAccount(password, history, handleGoalBalanceNotice));
                  },
                },
              ],
            })
          }
          className="close-account-btn-confirm semibold"
          data-testid="closeAccount_confirm_button"
        >
          {t("closeMyAccount")}
        </IonButton>
      </IonFooter>
    </>
  );
};
