import React from "react";
import {
  IonContent,
  IonRow,
  IonPage,
  IonCol,
  IonButton,
  IonToolbar,
  IonFooter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { WelcomeSlides } from "./WelcomeSlides";
import { PAGE_URLS } from "../../../constants";
import "./onboarding.scss";
import { OnboardingNavHeader } from "./OnboardingNavHeader";

import { trackEvent } from "../../../vendors/monitoring";
import { useSearchParam } from "../../../hooks/useSearchParam";
import { checkOfferCode } from "../../../redux/actions";
import { useDispatch, useSelector } from "../../../redux/hooks";
import { getUsersState } from "../../../redux/selectors";

export const Welcome: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { discountCode } = useSelector(getUsersState);
  useSearchParam('offer-code', offerCode => dispatch(checkOfferCode(offerCode.toUpperCase())));

  return (
    <IonPage id="welcome-page">
      <OnboardingNavHeader />
      <IonContent fullscreen>
        {discountCode ? <div
          className="w-100 flex-center ion-padding-vertical font-sm semibold"
          style={{ backgroundColor: "#E4F9FC" }}
        >
          {t("offerCodeApplied", { discountCode })}
        </div> : null}
        <IonRow className="container">
          <IonCol>
            <WelcomeSlides />
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar no-border className="ion-text-center">
          <IonButton
            fill="clear"
            color="primary"
            size="large"
            data-testid="welcomeLogin"
            routerLink={PAGE_URLS.LOGIN}
            className="ion-margin-end ion-margin-bottom"
            onClick={() => trackEvent("click_signIn", { category: "onboard" })}
          >
            {t("signIn")}
          </IonButton>
          <IonButton
            color="primary"
            data-testid="signup"
            size="large"
            className="ion-margin-start ion-margin-bottom"
            routerLink={PAGE_URLS.SIGNUP_HAS_PARTNER}
            onClick={() => trackEvent("click_getStarted", { category: "onboard" })}
          >
            {t("signUpBtn")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};
