import React, { useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonLoading, IonToast } from "@ionic/react";
import { setLoading, setToastMessage } from "../../../redux/actions";
import { getUiState } from "../../../redux/selectors";
import AlertUi from "../Alerts/AlertUi";
import NoInternetUi from "../NoInternet/NoInternet";
import { GlobalModals } from './GlobalModals'

interface GlobalUiProps { }

const GlobalUi: React.FC<GlobalUiProps> = () => {
  const { loading, toastMessage, modal } = useSelector(getUiState);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    /*
    * when the device keyboard opens, the window "height" shrinks.
    * We need to save the original height for cases where an element should remain below (& behind) the keyboard.
    */
    document.documentElement.style.setProperty("--original-height", `${window.innerHeight}px`);
  }, []);

  return (
    <>
      <NoInternetUi />
      <IonLoading isOpen={loading} onDidDismiss={() => dispatch(setLoading(false))} />
      <IonToast
        isOpen={!!toastMessage}
        onDidDismiss={() => dispatch(setToastMessage(""))}
        message={toastMessage}
        position="top"
        duration={2500}
      />
      <GlobalModals type={modal.type} {...modal.props} />
      <AlertUi />
    </>
  );
};

export default GlobalUi;
