import { SET_SUBSCRIPTION } from "../actionTypes";

const initialState: SubscriptionState = {
  loading: true,
  subscription: {},
};

interface SubscriptionState {
  loading: boolean;
  subscription: any;
}

interface action {
  type: string;
  payload: any;
}

const subscription = (state: SubscriptionState = initialState, action: action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        subscription: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default subscription;
